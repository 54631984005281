@import 'app/assets/scss/index.scss';

.order-by-csv {
  &__import-manual-card {
    height: 100%;
    &__buttons-container {
      display: flex;
      flex-direction: column;
      margin: 15px 0 25px 0;
      button {
        width: fit-content;
        justify-content: flex-start !important;
      }
      .button-manual {
        margin-bottom: 20px;
        margin-right: 15px;
      }
      @include media-breakpoint-up(md) {
        flex-direction: row;
        margin: 15px 0 25px 0;
        .button-manual {
          margin-bottom: 0;
          margin-right: 15px;
        }
      }
    }
    &__text {
      margin-bottom: 20px !important;
      &:last-child {
        margin-bottom: 0 !important;
      }
      color: $bb-grey-2;
    }
  }

  &__upload-form {
    margin-top: 15px;
    height: 100%;
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    &-input {
      margin-top: 20px;
      display: flex;
      align-content: flex-end;
      flex-wrap: wrap;
      > div {
        width: 100%;
        margin-bottom: 0;
      }
    }
    &-button {
      margin-top: 20px;
      display: flex;
      align-content: flex-end;
      flex-wrap: wrap;
    }
  }

  &__result-upload-form {
    margin-top: 15px;
    @include media-breakpoint-up(lg) {
      margin-top: 25px;
    }
    #import-csv-result {
      .column-description {
        min-width: 200px;
      }
      .error {
        color: $bb-red;
        .date-formatter {
          p {
            color: $bb-red !important;
          }
          .date-formatter__hour {
            color: $bb-red-medium !important;
          }
        }
      }
    }
  }

  .import-csv-table-header {
    max-width: 100px;
    white-space: break-spaces;
  }

  .error-information-tooltip svg {
    margin-left: 0.25rem;
  }
}
