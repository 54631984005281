.bb-faqs {
  .faq {
    &__title {
      border-bottom: 0.5px solid var(--interactive-disabled, #cccfd3);
      padding: 16px;
      display: flex;
      justify-content: space-between;
    }

    &__content{
        padding: 16px 0;
    }
  }
}
