@import '../../assets/scss/index.scss';

.bb-popup {
  &__close-icon {
    cursor: pointer;
  }

  @include media-breakpoint-up(md) {
    .modal-header,
    .modal-body {
      border-bottom: 1px solid $bb-grey-6;
    }
    .modal-footer {
      border-top: 0px;
    }
  }

  @include media-breakpoint-up(md) {
    .modal-header,
    .modal-body,
    .modal-footer {
      padding: 25px 30px;
    }
    .modal-header {
      align-items: flex-start !important;
    }
  }

  @include media-breakpoint-down(md) {
    .modal-header,
    .modal-body,
    .modal-footer {
      padding: 15px;
    }
  }
}
