.bb-button-dropdown {
  display: inline-block;
  .bb-dropdown {
    width: fit-content;
    height: auto;
    top: initial !important;
    left: initial !important;

    .bb-dropdown__row__title {
      width: 100%;
      padding: 11px 16px;
    }
  }
}
