@import 'app/assets/scss/utils/variables.scss';

#kt_subheader {
  margin-bottom: 0 !important;
  .icon-title {
    margin-right: 7px;
  }
  div.align-items-baseline {
    p {
      margin-bottom: 0 !important;
      margin-top: 2px !important;
      color: $bb-grey-2 !important;
      > .subheader-icon {
        margin-top: -5px;
        margin-right: 5px;
      }
    }
  }
  .breadcrumb-item {
    line-height: 1;
    display: flex;
    align-items: center;
    padding-left: 0;
    p {
      color: $bb-grey-3;
      line-height: 16px !important;
    }
    &:last-of-type {
      p {
        color: $bb-grey-2;
      }
    }
  }
  .breadcrumb-dot {
    .breadcrumb-item {
      &:first-of-type {
        &::after {
          content: '';
        }
      }
      &:after {
        content: '';
      }
      &__icon {
        font-size: 3px;
        margin-left: 7px;
      }
    }
  }
}
