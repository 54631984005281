@import 'app/assets/scss/index.scss';

.modal-products-manuals {
  &__description {
    margin-bottom: 25px !important;
  }

  &__languages {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(4, 1fr);
      gap: 26px;
    }

    &-container {
      display: inline-flex;
      align-items: center;
      grid-column: auto / span 1;

      &-image {
        width: 17px;
        margin-right: 10px;
        svg {
          border-radius: 4px;
        }
      }
    }
  }
  &__languages-footer {
    display: inline-flex;
    gap: 25px;
  }
}
