$elementSeparator: '__' !default;
$modifierSeparator: '--' !default;

// @access public
// @param {String} $element - Element's name
@mixin block($element) {
  .#{$element} {
    @content;
  }
}

// @access public
// @param {String} $element - Element's name
@mixin element($element) {
  &#{$elementSeparator + $element} {
    @content;
  }
}

// @access public
// @param {String} $modifier - Modifier's name
@mixin modifier($modifier) {
  @at-root {
    // '&' is a double-wrapped list,
    // Get the oldest ancestor
    $oldest_ancestor: nth(&, 1);
    // parent will be the last item in ancestors
    $parent: nth($oldest_ancestor, length($oldest_ancestor));
    // modifier should have all properties of parent
    #{$parent + $modifierSeparator + $modifier} {
      @extend #{$parent};
    }
    // '@content' will be in a nested selector however, if that is the context
    #{&}#{$modifierSeparator + $modifier} {
      @content;
    }
  }
}

// @alias block
@mixin b($element) {
  @include block($element) {
    @content;
  }
}

// @alias element
@mixin e($element) {
  @include element($element) {
    @content;
  }
}

/// @alias modifier
@mixin m($modifier) {
  @include modifier($modifier) {
    @content;
  }
}

// Example
// .bloque{
//     margin:0 auto;
//     &__boton{
//         border: 1px solid black;
//         &--rojo{
//              background:red;
//         }
//     }
// }
