@import 'app/assets/scss/utils/variables.scss';

.bb-time {
  display: flex;
  margin-bottom: 23px;

  &:last-child {
    margin-bottom: 0px;
  }

  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    &--red {
      color: $bb-red;
    }
    &--green {
      color: $bb-green;
    }
    &--yellow {
      color: $bb-primary;
    }
    &--blue {
      color: $bb-secondary;
    }
    &--grey {
      color: $bb-grey-2;
    }

    > p {
      margin: 0px 0px 5px 0px;
    }
  }

  &__spaced {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &__circle {
      width: 13px;
      height: 13px;
      border-radius: 100%;
      border: 3px;
      border-style: solid;
      box-sizing: border-box;
      margin: 0px 8px;

      &--red {
        border-color: $bb-red;
      }
      &--green {
        border-color: $bb-green;
      }
      &--yellow {
        border-color: $bb-primary;
      }
      &--blue {
        border-color: $bb-secondary;
      }
      &--grey {
        border-color: $bb-grey-3;
      }
    }

    &__line {
      position: absolute;
      width: 3px;
      background: #e5eaee;
      border-radius: 8px;
      height: 50px;
      padding: 5px 0px;
      margin-top: 18px;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &--red {
      color: $bb-red;
    }
    &--green {
      color: $bb-green;
    }
    &--yellow {
      color: $bb-primary;
    }
    &--blue {
      color: $bb-secondary;
    }
    &--grey {
      color: $bb-grey-3;
    }

    > p {
      margin: 0px 0px 5px 0px;
    }
  }
}
