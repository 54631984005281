@import 'app/assets/scss/index.scss';

.devolution-chat {
  .devolutions-chat-message {
    &__header {
      display: flex;
      margin-bottom: 16px;
      &__label {
        margin-left: 7px;
        color: $bb-grey-4;
        font-size: 12px !important;
      }

      p,
      label {
        margin-bottom: 0;
      }
    }

    &__body {
      p {
        color: $bb-grey-3;
        max-width: 500px;
      }
    }

    svg {
      margin-right: 10px;
    }
  }
  form {
    width: 81vw;
    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }
  .bb-textarea-form-group {
    width: 81vw;
    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }
}
