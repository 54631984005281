.bb-modal {
  &__container {
    &__header {
      display: flex;
      z-index: 99;
      & > div {
        & > p {
          color: #464e5f;
        }
      }
    }
    &__body {
      display: flex;
      position: relative !important;
      width: 100%;
      max-height: 300px;
      padding: 10px;
      z-index: 99;
    }
    &__footer {
      display: flex;
      z-index: 999;
    }
  }
}
