@import 'app/assets/scss/index.scss';

.title {
  color: $bb-grey-2;
  font-size: 14px !important;
  font-weight: bold !important;

  @include media-breakpoint-up(md) {
    font-size: 16px !important;
  }
}

.add-credit-card-form {
  gap: 20px;

  &__required-label {
    color: $bb-red;
  }

  &__form-inline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  &__horizontal-in-desktop {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  &__switch {
    &--title {
      margin-bottom: 10px !important;
      color: $bb-grey-2 !important;
    }
    &--label {
      color: $bb-grey-4 !important;
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    &__back {
      margin-right: 15px;
    }
  }
}

#paycometPaymentForm {
  gap: 20px;
  display: flex;
  flex-direction: column;
}

#paymentErrorMsg {
  color: $bb-red;
}
