@import 'app/assets/scss/index.scss';

.bb-pack-info-card {
  background-color: $bb-white;
  box-shadow: 10px 10px 30px rgba(181, 181, 195, 0.4);
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__cards-container {
    padding: 25px;
  }

  .bb-pack-info-header {
    flex: 1;
  }

  &--normal {
    .bb-pack-info-card__cards-container {
      margin-top: -60px;
      padding: 15px;
      @include media-breakpoint-up(lg) {
        padding: 25px;
        margin-top: 0;
      }
    }
  }

  &--small {
    .bb-pack-info-card__cards-container {
      margin-top: -60px;
      padding: 20px 15px 0;
      margin-bottom: 15px;
    }
  }
}
