@import 'app/assets/scss/index.scss';

.trackings {
  &-card-container {
    padding: 25px 30px;
  }

  &-table {
    &__body {
      margin-top: 20px;

      &-buttons {
        display: flex;
        margin-top: 20px;
        @include media-breakpoint-up(md) {
          margin-bottom: 10px;
          margin-top: 0;
        }

        button {
          margin-right: 15px;
        }
      }

      .datatable-filters-tags {
        margin-bottom: 10px;
      }
    }
  }

  &-card-notifications {
    margin-top: 25px !important;

    &__active {
      &-msg {
        margin-top: 25px;
      }
    }
    &__active {
      &-form {
        margin-top: 25px;

        &-label {
          margin-bottom: 5px;
        }

        div.col-4 {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }

  .pagination__container {
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    .pagination-links {
      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }
  }
}
