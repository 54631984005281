@import 'app/assets/scss/utils/variables.scss';

.user-profile-dropdown {
  .symbol {
    .symbol-label {
      background-color: $bb-secondary-light !important;
      color: $bb-secondary;
      font-weight: bold !important;
      font-size: 14px !important;
      line-height: 16px !important;
    }
  }

  .dropdown-menu {
    > .dropdown-menu-header {
      background-image: url('../../assets/png/bg-user-profile-header.png');

      .symbol-label {
        background-color: $bb-white !important;
        color: $bb-primary;
        font-weight: bold !important;
        font-size: 18px !important;
        line-height: 21px !important;
      }

      .name-container {
        display: flex;
        flex-direction: column;

        p:first-child {
          color: $bb-white;
        }
      }

      .symbol-name {
        font-weight: bold !important;
        font-size: 14px !important;
        line-height: 16px !important;
        margin-left: 4px;
      }
    }

    .navi .navi-link > .navi-text {
      display: flex;
      align-items: center;
      p {
        margin-left: 7px;
      }
    }
  }

  &__greeting {
    color: #dddfe5;

    &--name {
      color: #7e8299;
    }
  }
}

.header .bb-topbar .user-profile-dropdown .bb-btn-item {
  height: auto !important;
  padding: 6px;
}
