@import 'app/assets/scss/index.scss';

.bb-notification-panel {
  &__section {
    &-header {
      margin-bottom: 20px !important;

      @include media-breakpoint-up(lg) {
        margin-bottom: 25px !important;
      }
    }
    &-subtitle {
      margin-bottom: 20px;

      @include media-breakpoint-up(lg) {
        margin: 0;
      }
    }
  }

  &--big-font {
    font-size: 14px !important;
    color: $bb-grey-2 !important;

    @include media-breakpoint-up(lg) {
      font-size: 16px !important;
    }
  }

  &--regular-font {
    font-size: 13px !important;
    color: $bb-grey-2 !important;

    @include media-breakpoint-up(lg) {
      font-size: 14px !important;
    }
  }
}
