@import '../../assets/scss/index.scss';

.course-card {
  box-shadow: 0px 4px 29.6px 0px rgba(0, 0, 0, 0.25);
  &--disabled {
    .course-card-header {
      img {
        filter: grayscale(1);
      }
    }
    .course-card-body {
      background-color: #e4e5e5 !important;
      .course-card-teacher,
      .course-card-description {
        color: #697077 !important;
      }
    }
  }
  &-header {
    display: flex;
    position: relative;
    iframe {
      width: -webkit-fill-available;
    }
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 50px;
      color: #000;
      --fa-secondary-color: white;
    }
    img {
      width: -webkit-fill-available;
    }
  }
  &-body {
    padding: 16px;
    background-color: $bb-white;
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: space-between;
  }
  &-content-price {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
    height: 100%;
  }
  &-content-chips {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &-title {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &-name {
    overflow: hidden;
    color: $bb-black-pure;
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
  }
  &-teacher {
    color: #373d42;
    align-items: center;
    align-content: center;
    gap: 4px;
    align-self: stretch;
    flex-wrap: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    strong {
      margin-left: 4px;
    }
  }
  &-description {
    color: #373d42;
    flex-wrap: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-feature-settings: 'clig' off, 'liga' off;
    -webkit-line-clamp: var(--lineNumber);
    font-size: 14px !important;
    font-style: normal;
    font-weight: 300 !important;
    line-height: 21px !important;
  }
  &-tags {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 4px;
    align-self: stretch;
    flex-wrap: wrap;
  }
  &-cta {
    button {
      width: 100%;
      p {
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 22px !important;
      }
    }
    .bb-btn--disabled {
      background-color: #cccfd3 !important;
      color: $bb-white !important;
      opacity: 1 !important;
    }
  }
}
