@import 'app/assets/scss/index.scss';

.bb-payment-methods-paypal {
  &__paypal-item {
    display: flex;
    align-items: center;
    padding: 20px 15px;
    border: 1px dashed #e5eaee;
    margin: 0 !important;

    @include media-breakpoint-up(md) {
      padding: 10px 0;
      border: none;
      flex-wrap: nowrap !important;
    }

    &--image {
      max-width: 30% !important;
      width: auto !important;
      margin-right: 15px;
      padding: 0 !important;
      @include media-breakpoint-up(md) {
        margin: 0;
      }
      & img {
        width: 100%;
        max-width: 75.87px;
        margin-right: 15px;
      }
    }

    &--info {
      max-width: calc(70% - 15px) !important;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 0 !important;

      & > p {
        display: block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-title {
        color: #b5b5c3;
        margin-bottom: 4px !important;
        font-size: 13px;

        @include media-breakpoint-up(md) {
          font-size: 14px;
        }
      }

      &-subtitle {
        color: #464e5f;
        font-size: 13px;
        font-weight: 500;

        @include media-breakpoint-up(md) {
          font-size: 14px;
        }
      }
    }

    &--interface {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      padding: 0 !important;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      &.right-align {
        justify-content: flex-end;
      }
    }

    &--notification {
      margin: 15px 0;
      white-space: nowrap;

      @include media-breakpoint-up(md) {
        margin: 0;
      }
    }

    &--actions {
      display: flex;

      @include media-breakpoint-up(md) {
        justify-content: flex-end;
      }

      & > button {
        padding: 8px 15px !important;
        height: auto;
        margin-top: 0;
        margin-right: 15px;
        margin-top: 15px;

        @include media-breakpoint-up(md) {
          margin: 0;
          margin-left: 7px;
          height: 28px;
          width: 28px;
        }

        & > p {
          font-size: 14px !important;
          line-height: normal !important;
        }
      }
    }
  }

  &__p-info {
    color: $bb-grey-2;
    font-size: 14px !important;
    margin-bottom: 20px !important;

    @include media-breakpoint-up(md) {
      font-size: 16px !important;
      margin-bottom: 0 !important;
    }
  }
}
