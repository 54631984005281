@import 'app/assets/scss/index.scss';

.shopify-form {
  &__footer {
    padding: 20px 15px;
    margin: 0 !important;
    display: flex;
    justify-content: flex-end;

    @include media-breakpoint-up(lg) {
      margin: 30px;
    }
  }

  &__popup-warning-changes {
    &-footer {
      display: inline-flex;
      gap: 16px;

      @include media-breakpoint-up(lg) {
        gap: 24px;
      }
    }
  }
}
