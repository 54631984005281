@import '../../../../../assets/scss/index.scss';

.bb-synchronize-api-key {
  &__p-key {
    word-wrap: break-word;
  }

  &__row-info {
    align-items: center;
  }

  @include media-breakpoint-up(sm) {
    button {
      margin-top: 25px;
    }

    &__p-des {
      margin-top: 25px !important;
    }

    &__row-info {
      padding-top: 25px;
    }
  }

  @include media-breakpoint-down(sm) {
    button {
      margin-top: 20px;
    }

    &__p-des {
      margin-top: 20px !important;
    }

    &__row-info {
      padding-top: 20px;
    }

    &__p-info {
      margin-bottom: 7px !important;
      font-family: 'Roboto' !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 13px !important;
      line-height: 15px !important;
    }
  }

  @include media-breakpoint-up(lg) {
    p.big {
      margin-bottom: 25px !important;
    }
  }

  @include media-breakpoint-down(lg) {
    p.big {
      margin-bottom: 20px !important;
    }
  }
}
