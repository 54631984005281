@import '../../assets/scss/index.scss';

.dropshipping-shop-card-ecommerce-list {
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &-logos {
      display: flex;
      flex-direction: row;
      align-items: center;
      .ecommerce-logo {
        height: 42px;
        width: 42px;
        margin-right: 15px;
      }
      .logo-client {
        aspect-ratio: auto;
        height: auto;
        max-height: 42px;
        width: auto;
        @include media-breakpoint-down(sm) {
          max-width: 100px;
        }
        max-width: 120px;
      }
    }
    &-info {
      align-items: flex-end;
      display: flex;
      flex-flow: column;

      .url {
        color: $bb-grey-2;
        text-align: end;
      }

      .catalog {
        color: $bb-grey-3;
        text-transform: capitalize;
      }
    }
  }
}
