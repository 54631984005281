@import 'app/assets/scss/index.scss';

.datatable-filters-tags {
  display: flex;
  flex-flow: wrap;
  margin-top: 20px;

  @include media-breakpoint-up(lg) {
    margin-top: 25px;
  }
  .bb-tag {
    margin-right: 5px;
    color: $bb-grey-3;
    &:hover {
      color: $bb-white;
    }
  }
}
