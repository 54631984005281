@import 'app/assets/scss/utils/variables.scss';

.lang-selector {
  .flag-icon {
    height: 18px;
    width: 18px;
  }

  &-dropdown-options {
    width: 120px;
    max-width: 120px;

    .scrollbar-container {
      font-size: 12px;
      line-height: 14px;

      .navi-item {
        &:hover {
          cursor: pointer;
        }
        .navi-link {
          &:hover {
            background-color: transparent !important;
          }
          .navi-text {
            font-size: 12px !important;
            line-height: 14px !important;
          }
        }
      }
    }
  }

  &:hover {
    .flag-iso-code {
      color: $bb-secondary;
    }

    .flag-dropdown {
      [fill] {
        fill: $bb-secondary !important;
      }
    }
  }
}
