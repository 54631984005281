@import 'app/assets/scss/index.scss';

.image-view-container {
  .data-table-components {
    &__container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 16px;
      @include media-breakpoint-up(md) {
        grid-gap: 24px;
      }

      .image-view {
        &.big {
          grid-column: auto / span 4;
        }

        &.medium {
          grid-column: auto / span 4;
          @include media-breakpoint-up(md) {
            grid-column: auto / span 2;
          }
        }

        &.small {
          grid-column: auto / span 2;
          @include media-breakpoint-up(md) {
            grid-column: auto / span 1;
          }
        }

        &__image {
          height: 150px;
          background-size: cover !important;
          background-position: center !important;
        }
      }

      .pagination {
        grid-column-start: 1;
        grid-column-end: 5;
      }
    }
  }
}
