@import 'app/assets/scss/index.scss';

.social-selling-resources {
  .data-table-components {
    &__container {
      gap: 16px;
      display: flex;
      @include media-breakpoint-up(lg) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 24px;
      }
      @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(3, 1fr);
      }

      .social-selling {
        background: $bb-white;
        box-shadow: 0px 2px 6px 2px rgba(126, 130, 153, 0.15);
        border-radius: 4px;
        display: inline-flex;
        padding: 16px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: relative;

        @include media-breakpoint-up(lg) {
          grid-column: auto / span 1;
          display: flex;
          flex-direction: column;
          background-color: $bb-grey-9;
        }

        &__checkbox {
          margin-right: 20px;
          @include media-breakpoint-up(lg) {
            position: absolute;
            top: 20px;
            left: 20px;
          }
        }

        &__image {
          margin-right: 20px;
          @include media-breakpoint-up(lg) {
            margin-right: 0;
            width: 100%;
          }

          img {
            height: 60px;
            width: 60px;
            @include media-breakpoint-up(lg) {
              height: 100%;
              width: 100%;
            }
          }
        }

        &__description {
          width: 100%;
          @include media-breakpoint-up(lg) {
            height: 100%;
            margin-top: 20px;
          }
        }
      }

      .pagination {
        @include media-breakpoint-up(lg) {
          grid-column: auto / span 2;
        }
        @include media-breakpoint-up(xl) {
          grid-column: auto / span 3;
        }
      }
    }
  }
}
