@import 'app/assets/scss/utils/variables.scss';
.bb-react-slider {
  $height: 12px;
  .MuiSlider-rail {
    background-color: $bb-grey-9 !important;
    height: $height;
  }

  .MuiSlider-track {
    background-color: $bb-secondary !important;
    height: $height;
    border: 0;
  }
  .MuiSlider-valueLabel {
    background-color: $bb-secondary-light !important;
    color: $bb-secondary !important;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .MuiSlider-thumb {
    background-color: $bb-secondary-dark !important;
  }
}
