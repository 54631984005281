@import 'app/assets/scss/index.scss';

.print-form {
  margin-top: 20px;
  gap: 20px;
  @include media-breakpoint-up(lg) {
    gap: 0;
  }
  &__text {
    margin-bottom: 20px !important;
    color: $bb-grey-3;
    @include media-breakpoint-up(lg) {
      margin-bottom: 25px !important;
    }
  }
  &__col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    button {
      width: fit-content;
    }
  }
}
