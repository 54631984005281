@import 'app/assets/scss/index.scss';

.shopify-form {
  &__container {
    &-title {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;

      .button-view-more {
        display: none !important;
        white-space: nowrap;

        @include media-breakpoint-up(lg) {
          display: flex !important;
        }
      }
    }
    .shopify-form__input-wrapper {
      .shopify-form__input {
        .bb-select {
          div [role='button'] {
            svg {
              path {
                color: $bb-white !important;
              }
            }
          }
        }
      }
    }
  }
}
