@import '../../assets/scss/index.scss';

.marketplace-connector-list {
  background-color: $bb-white;
  padding: 20px 15px !important;
  padding-bottom: 2px !important;
  box-shadow: 5px 5px 30px rgba(181, 181, 195, 0.4);
  border-radius: 4px;

  @include media-breakpoint-up(lg) {
    padding: 25px 30px !important;
    padding-bottom: 10px !important;
  }

  &__title {
    margin-bottom: 15px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 15px;
    }
  }
}
