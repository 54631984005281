@import '../../assets/scss/index.scss';

.bb-form-group {
  > .input-file-group {
    display: flex;
    height: 38px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    background-color: $bb-grey-9;
    border-color: $bb-grey-9;
    border-radius: 4px;
    color: $bb-grey-4;
    padding: 10px;
    align-items: center;
    justify-content: flex-start;
    user-select: none;
    cursor: pointer;

    .input-file-group-container {
      display: flex;
      width: calc(100% - 26px);
      align-items: center;
      justify-content: space-between;

      & > p {
        width: inherit;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.--spaced {
          margin-left: 19px;
        }
      }

      & > input {
        display: none;
      }

      & > .invalid-file-field-icon {
        width: 26px;
        height: 26px;
        background-image: url('../../assets/svg/engine-warning.svg') !important;
        background-repeat: no-repeat !important;
      }

      & > .valid-file-field-icon {
        width: 26px;
        height: 26px;
        background-image: url('../../assets/svg/check.svg') !important;
        background-repeat: no-repeat !important;
      }
    }

    &.input-file-disabled {
      background-color: $bb-grey-7 !important;
      border: 1px solid $bb-grey-5 !important;
      color: $bb-grey-4 !important;
      opacity: 0.7 !important;
      background-image: none !important;
      cursor: not-allowed !important;
    }

    &.invalid-file-field {
      border-color: $bb-red-light !important;
      background-color: $bb-red-light !important;
    }

    &.valid-file-field {
      background-color: $bb-grey-9 !important;
      border-color: $bb-grey-9 !important;
    }

    &:active,
    &:focus {
      background-color: $bb-grey-7 !important;
      border-color: $bb-grey-7 !important;
    }
  }
}
