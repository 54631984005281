h1 {
  font-family: Roboto Condensed !important;
  font-size: 46px !important;
  line-height: 53.91px !important;
  font-weight: 400 !important;

  @include media-breakpoint-up(lg) {
    font-size: 78px !important;
    line-height: 91.41px !important;
  }

  &.bold {
    font-weight: bold !important;
  }
}

h2 {
  font-family: Roboto Condensed !important;
  font-size: 32px !important;
  line-height: 37.5px !important;
  font-weight: 400 !important;

  @include media-breakpoint-up(lg) {
    font-size: 49px !important;
    line-height: 57.42px !important;
  }

  &.bold {
    font-weight: bold !important;
  }
}

h3 {
  font-family: Roboto Condensed !important;
  font-size: 24px !important;
  line-height: 28.13px !important;
  font-weight: 400 !important;

  @include media-breakpoint-up(lg) {
    font-size: 39px !important;
    line-height: 45.7px !important;
  }

  &.bold {
    font-weight: bold !important;
  }
}

h4 {
  font-family: Roboto Condensed !important;
  font-size: 20px !important;
  line-height: 23.44px !important;
  font-weight: 400 !important;

  @include media-breakpoint-up(lg) {
    font-size: 28px !important;
    line-height: 32.81px !important;
  }

  &.bold {
    font-weight: bold !important;
  }
}

h5 {
  font-family: Roboto !important;
  font-size: 16px !important;
  line-height: 18.75px !important;
  font-weight: 400 !important;

  @include media-breakpoint-up(lg) {
    font-size: 20px !important;
    line-height: 23.44px !important;
  }

  &.bold {
    font-weight: bold !important;
  }
}

p {
  font-family: Roboto !important;
  font-size: 13px !important;
  line-height: 15.23px !important;
  font-weight: 400 !important;
  margin-bottom: 0 !important;

  @include media-breakpoint-up(lg) {
    font-size: 14px !important;
    line-height: 16.14px !important;
  }

  &.bold {
    font-weight: 700 !important;
  }

  &.medium {
    font-weight: 500 !important;
  }

  &.big {
    font-size: 14px !important;
    line-height: 16.41px !important;

    @include media-breakpoint-up(lg) {
      font-size: 16px !important;
      line-height: 18.75px !important;
    }
  }

  &.small {
    font-size: 12px !important;
    line-height: 14.08px !important;
  }
}

a {
  color: $bb-secondary !important;
  &:hover {
    color: $bb-secondary-dark !important;
  }
}

.bb-link {
  font-family: Roboto !important;
  font-size: 14px !important;
  line-height: 16.41px !important;
  font-weight: 400 !important;
  color: $bb-secondary !important;
  text-decoration: underline !important;
  cursor: pointer;

  &.small {
    font-size: 12px !important;
    line-height: 14.06px !important;
  }

  &.big {
    font-size: 16px !important;
    line-height: 18.75px !important;
  }

  &.disabled {
    opacity: 0.6;
    cursor: auto;
  }
}

.subtitle {
  font-family: Roboto !important;
  font-size: 18px !important;
  line-height: 21.09px !important;
  font-weight: 400 !important;
  color: $bb-grey-3;
  &.small {
    font-size: 14px !important;
    line-height: 16.41px !important;
    color: $bb-grey-2;
  }
  &.bold {
    font-weight: 700 !important;
  }
}

label {
  font-family: Roboto !important;
  font-size: 14px !important;
  line-height: 14.08px !important;
  font-weight: 400 !important;
  color: $bb-grey-2;

  &.small {
    font-size: 12px !important;
    line-height: 14.08px !important;
  }

  &.medium {
    font-weight: 500 !important;
  }

  &.bold {
    font-weight: bold !important;
  }
}
