@import 'app/assets/scss/index.scss';

.bb-basic-datatable {
  .react-bootstrap-table-pagination {
    justify-content: center;

    @include media-breakpoint-up(lg) {
      justify-content: space-between;
      flex-direction: row-reverse;
      margin-top: 25px;
    }

    > .col-md-6.col-xs-6.col-sm-6.col-lg-6 {
      width: 100%;
      justify-content: center;
      display: flex;
      margin: 20px 0;

      p.pagination-toolbar__text {
        @include media-breakpoint-up(lg) {
          padding-top: 15px;
        }
      }

      @include media-breakpoint-up(lg) {
        width: 50%;
        justify-content: end;
        margin: 0;
      }
    }

    .pagination-links {
      width: 100%;
      display: flex;
      justify-content: center;
      @include media-breakpoint-up(lg) {
        width: 50%;
        justify-content: initial;
        padding-left: 13px;
      }
    }
  }
}
