input[type='checkbox'].bb-checkbox {
  -webkit-appearance: none;
  appearance: none;
  margin-left: 5%;
  margin-top: 5%;
  width: 18px;
  height: 18px;
  border: 2px solid $bb-grey-4;
  border-radius: 4px;
  background-color: $bb-white;

  &:checked {
    background-color: $bb-secondary;
    background-image: url('../../svg/check-input.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 12px;
    border: 2px solid $bb-secondary;
    place-content: center;
  }
}
