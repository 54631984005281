@import 'app/assets/scss/index.scss';

.purse-modal {
  display: flex;
  &-deposit {
    &-modal {
      .modal-header {
        padding: 25px 30px !important;
      }
      .modal-body {
        display: flex;
        padding: 25px 30px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;

        .bb-notification {
          max-width: 100% !important;
        }

        .bb-form-group {
          width: 100%;
          margin-bottom: 0;
        }

        .bb-notification__body {
          margin-bottom: 0 !important;
        }
        .bb-notification__content {
          justify-content: center !important;
        }

        .bankwire-payment {
          cursor: pointer;
          border-radius: 4px;
          background: #fff;
          box-shadow: 0px 4px 8px 3px rgba(126, 130, 153, 0.15);
          display: flex;
          padding: 16px 24px;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          width: 100%;
          &.checked {
            background: #dceaf6;
          }

          .title {
            font-size: 18px !important;
            font-style: normal !important;
            font-weight: 600 !important;
            line-height: 24px !important;
          }

          .subtitle {
            font-size: 12px !important;
            font-style: normal !important;
            font-weight: 300 !important;
            line-height: 16px !important;
          }
        }

        .other-payment-methods {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          gap: 17px;
          width: 100%;
          justify-content: space-evenly;

          &__card {
            cursor: pointer;
            border-radius: 4px;
            background: #fff;
            box-shadow: 0px 2px 6px 2px rgba(126, 130, 153, 0.15);
            display: flex;
            flex-direction: column;
            padding: 24px;
            gap: 16px;
            align-items: center;

            &.checked {
              background: #dceaf6;
            }

            img {
              width: 74px;
              height: 44px;
            }

            span {
              font-size: 14px;
              font-style: normal;
              font-weight: 300;
              line-height: 19px;
            }

            .comision {
              font-size: 12px;
              font-style: normal;
              font-weight: 300;
              line-height: 16px;
            }
          }
        }
      }
    }
  }

  &-withdrawal {
    @include media-breakpoint-up(md) {
      margin-right: 15px;
    }

    margin-right: 15px;
    &--body {
      &_type {
        margin-bottom: 25px !important;
        margin-top: 10px !important;
        .bb-select {
          p {
            color: $bb-grey-3;
          }
        }
      }
    }
  }
}

p.purse-bottom-label {
  color: $bb-grey-3 !important;
  font-size: 12px !important;
  margin-top: 7px;
}
