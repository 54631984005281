@import 'app/assets/scss/index.scss';

.delivery-notes {
  &__products,
  &__delivery-note,
  &__trackings {
    margin-top: 15px;
    @include media-breakpoint-up(md) {
      margin-top: 25px;
    }
  }
  &__info{
    color: $bb-grey-3;
  }

  .bb-card__container__body {
    margin-top: 20px;

    @include media-breakpoint-up(md) {
      margin-top: 25px;
    }
  }

  .bb-data-table__searchbar {
    display: none;

    @include media-breakpoint-up(md) {
      margin-bottom: 25px;
      display: block;
    }
    .select-package-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      .bb-select {
        width: inherit;
      }
    }
  }
  .bb-data-table__filter {
    @include media-breakpoint-up(md) {
      padding-left: 0;
    }
  }
}
