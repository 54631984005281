@import 'app/assets/scss/index.scss';

.export-csv-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.bb-invoices__data-table {
  &__cell {
    color: $bb-grey-2;

    &__action-icons {
      display: flex;
    }

    &__action-icon {
      background-color: $bb-grey-8;
      width: 25px;
      height: 25px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 7px;
    }
  }

  &__date {
    &-bottom {
      color: $bb-grey-3;
    }
  }
}
