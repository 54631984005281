@import 'app/assets/scss/utils/variables.scss';

@for $i from 1 through 10 {
  .#{$fa-css-prefix}-#{$i}x {
    font-size: $i * 1em;
  }
}

// step-based scale (with alignment)
@each $size, $value in $fa-sizes {
  .#{$fa-css-prefix}-#{$size} {
    font-size: #{$value}px !important;
  }
}

.fa-duotone-group {
  color: var(--color);
  --fa-secondary-opacity: 0.3 !important;
}
