@import 'app/assets/scss/index.scss';

.bb-multichannel {
  &__secondary-cards {
    display: flex;
    flex-direction: column;
    gap: 15px;

    @include media-breakpoint-up(md) {
      gap: 25px;
      flex-direction: row;
    }
  }

  &__secondary-card {
    flex: 1;
    padding: 20px 15px;
    color: $bb-white;
    display: flex;
    flex-direction: column;
    box-shadow: 10px 10px 30px rgba(181, 181, 195, 0.4);
    border-radius: 4px;

    @include media-breakpoint-up(md) {
      padding: 30px 25px;
    }

    &__container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &__title {
      margin-bottom: 25px;

      @include media-breakpoint-up(md) {
        margin-bottom: 15px;
      }
    }

    &__contents {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-bottom: 35px;
      flex: 1;

      @include media-breakpoint-up(md) {
        max-width: 294px;
        margin-bottom: 15px;
      }
    }
  }

  &__marketplaces-card {
    background-color: $bb-green;
    background-image: url('../../assets/png/multichannel/marketplaces-background-mobile.png'),
      linear-gradient($bb-green, $bb-green) !important;
    background-repeat: no-repeat !important;
    background-position: 100% 100% !important;
    background-size: 50% !important;

    @include media-breakpoint-up(md) {
      background-image: url('../../assets/png/multichannel/marketplaces-background.png'),
        linear-gradient($bb-green, $bb-green) !important;
      background-size: 207px !important;
      background-position: 95% 100% !important;
    }
  }

  &__ecommerce-card {
    background-color: $bb-secondary;
    background-image: url('../../assets/png/multichannel/ecommerce-background-mobile.png'),
      linear-gradient($bb-secondary, $bb-secondary) !important;
    background-repeat: no-repeat !important;
    background-size: 50% !important;
    background-position: 100% 100% !important;

    @include media-breakpoint-up(md) {
      background-size: 254px !important;
      background-image: url('../../assets/png/multichannel/ecommerce-background.png'),
        linear-gradient($bb-secondary, $bb-secondary) !important;
    }
  }
}
