@import 'app/assets/scss/index.scss';

.course-card-dialog {
  display: flex;
  flex-direction: column;
  gap: 19px;
  padding: 24px;
  border-radius: 8px;

  &-header {
    width: 100%;
    height: 160px;
    @include media-breakpoint-up(md) {
      height: 370px;
    }
    iframe {
      height: 100%;
      width: inherit;
    }

    img {
      height: 100%;
      width: inherit;
      object-fit: contain;
    }
  }
  &-name {
    font-weight: 700 !important;
  }
  &-description {
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
  }
  &-content-button {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &-info {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px;
    align-self: stretch;
    flex-wrap: wrap;
  }
  &-content-info {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    gap: 16px;
    max-width: 710px;
    min-width: 284px;
  }
  &-features {
    align-items: flex-start;
    background: #fffaec;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    gap: 4px;
    min-width: 316px;
    padding: 8px 16px;
  }
  &-feature {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
    align-self: stretch;
    &-title {
      display: flex;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      i {
        color: #f2b801;
      }
    }
  }
  &-discount-button {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-self: stretch;
  }
}
.bb-dialog {
  padding: 0 !important;
  border-radius: 8px;
  overflow-y: scroll;
}
