@import 'app/assets/scss/index.scss';

.bb-pack-info-date {
  padding: 20px;
  margin: 10px 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @include media-breakpoint-up(lg) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__calendar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 7px;

    &__text {
      margin: 0;
      margin-left: 12px;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 10px;
      width: 100%;
      justify-content: center;
    }
  }
}
