// Root Font Sizes
$root-font-size: 14px; // Root font size for desktop mode
$root-font-size-lg: 16px; // Root font size for tablet mode

// Colors
$bb-primary: #ffce33;
$bb-primary-dark: #f6bb00;
$bb-primary-light: #fff0c2;
$bb-secondary: #0090ff;
$bb-secondary-dark: #0072ca;
$bb-secondary-medium: #b3deff;
$bb-secondary-light: #e8f5ff;
$bb-orange: #ef6327;
$bb-orange-medium: #f7b497;
$bb-orange-light: #fce7df;
$bb-green: #1bc5bd;
$bb-green-medium: #d7f9ef;
$bb-green-light: #effdfc;
$bb-red: #f64e60;
$bb-red-medium: #fccacf;
$bb-red-light: #feeff1;
$bb-purple: #8950fc;
$bb-purple-medium: #b896fd;
$bb-purple-light: #eee5ff;
$bb-black-pure: #000000;
$bb-grey-1: #1e1e2d;
$bb-grey-2: #464e5f;
$bb-grey-3: #7e8299;
$bb-grey-4: #b5b5c3;
$bb-grey-5: #dddfe5;
$bb-grey-6: #e5eaee;
$bb-grey-7: #ecf0f3;
$bb-grey-8: #eef0f8;
$bb-grey-9: #f3f6f9;
$bb-white: #ffffff;

// Icon sizes
$bb-icon-small: 18px;
$bb-icon-medium: 26px;
$bb-icon-large: 34px;

$fa-css-prefix: fa !default;

$fa-size-scale-2xs: 8 !default;
$fa-size-scale-xs: 10 !default;
$fa-size-scale-sm: 12 !default;
$fa-size-scale-base: 14 !default;
$fa-size-scale-lg: 18 !default;
$fa-size-scale-xl: 26 !default;
$fa-size-scale-2xl: 34 !default;


$fa-sizes: (
  '2xs': $fa-size-scale-2xs,
  'xs': $fa-size-scale-xs,
  'sm': $fa-size-scale-sm,
  'lg': $fa-size-scale-lg,
  'xl': $fa-size-scale-xl,
  '2xl': $fa-size-scale-2xl
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);
