@import '../../assets/scss/index.scss';

.bb-dropdown {
  position: absolute;
  top: 23px;
  left: 1px;
  width: 99px;
  height: auto;
  background: $bb-white;
  box-shadow: 0px 3px 10px rgba(216, 216, 216, 0.6);
  border-radius: 4px;
  padding: 10px 0px;
  z-index: 999;
  user-select: none;

  &__row {
    display: flex;
    width: 100%;
    height: 39px;
    background: $bb-white;
    cursor: pointer;

    &:hover {
      background: $bb-secondary-light;
      & > span {
        color: $bb-secondary;
      }
    }

    &__title {
      display: flex;
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      align-items: center;
      color: $bb-grey-2;
      padding: 10px 0px 10px 15px;
      text-transform: capitalize;
    }
  }
}
