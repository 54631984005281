@import 'app/assets/scss/index.scss';

.export-csv-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.bb-trackings__data-table {
  &__cell {
    color: $bb-grey-2;

    &.red {
      color: $bb-red;
    }

    &.blue {
      color: $bb-secondary;
    }

    &__action-icons {
      display: flex;
    }

    &__action-icon {
      background-color: $bb-grey-8;
      width: 25px;
      height: 25px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 7px;
    }

    &--tag {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 7px 15px;
      text-align: center;
      border-radius: 4px;
      max-width: 117px;

      & label {
        font-size: 12px !important;
        font-weight: 500 !important;
        margin-bottom: 0;
      }

      &-tooltip {
        margin: 4px;
        display: inline;
      }
    }
  }

  &__date {
    &-bottom {
      color: $bb-grey-3;

      &.red {
        color: $bb-red-medium;
      }

      &.blue {
        color: $bb-secondary-medium;
      }
    }
  }
}
