@import 'app/assets/scss/index.scss';

.input-filter {
  &__results {
    &-container {
      z-index: 1;
      position: absolute !important;
      top: 38px;
      background: $bb-white;
      width: 100%;
      box-shadow: 0px 3px 6px -3px rgba(23, 24, 24, 0.08),
        0px 8px 20px -4px rgba(23, 24, 24, 0.12);
      border-radius: 8px;
      max-height: 120px;
      overflow: scroll;
      overflow-x: hidden;
    }
  }
}
