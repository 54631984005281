@import 'app/assets/scss/utils/variables.scss';

.pagination-links {
  &__btn {
    height: 32px;
    width: 32px;
    color: $bb-grey-2;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    margin-right: 2px;

    &:hover {
      color: $bb-secondary;
      background-color: $bb-secondary-light;
    }

    &.active {
      color: $bb-white;
      background-color: $bb-secondary;
    }
  }
}
