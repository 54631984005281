@import 'app/assets/scss/index.scss';

.bb-notification {
  border-radius: 4px;
  padding: 15px;
  color: $bb-grey-2;
  max-width: 315px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media-breakpoint-up(md) {
    max-width: 100%;
  }

  &__structure {
    display: flex;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__icon {
    margin-left: 4px;
    margin-right: 11px;
    height: fit-content;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  &__close-btn {
    margin-left: 11px;

    & .fa-duotone-group .fa-secondary {
      opacity: 1 !important;
    }
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    @include media-breakpoint-up(md) {
      justify-content: flex-start;
      margin-left: 25px;
    }
  }

  &.bb-notification-success {
    background-color: $bb-green-light;
    border-color: $bb-green-light;
  }

  &.bb-notification-reminder {
    background-color: $bb-purple-light;
    border-color: $bb-purple-light;
  }

  &.bb-notification-warning {
    background-color: $bb-orange-light;
    border-color: $bb-orange-light;
  }

  &.bb-notification-info {
    background-color: $bb-secondary-light;
    border-color: $bb-secondary-light;
  }

  &.bb-notification-grey {
    background-color: $bb-grey-7;
    border-color: $bb-secondary-light;
  }
}
