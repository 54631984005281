@import 'app/assets/scss/index.scss';

.csv-xml-files {
  margin-top: 15px;
  @include media-breakpoint-up(md) {
    margin-top: 0;
  }

  .category-list__section {
    margin-top: 20px;

    @include media-breakpoint-up(lg) {
      max-width: 85%;
    }
  }
}
