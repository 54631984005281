@import 'app/assets/scss/index.scss';

.bb-form-group {
  > .input-group {
    input {
      height: 38px !important;
      font-size: 14px !important;
      line-height: 16px !important;
      font-weight: 400 !important;
      background-color: $bb-grey-9 !important;
      border-color: $bb-grey-9 !important;
      color: $bb-grey-2 !important;

      &::-webkit-inner-spin-button {
        display: none !important;
      }

      &::placeholder {
        color: $bb-grey-4 !important;
      }

      &:active,
      &:focus {
        background-color: $bb-grey-7 !important;
        border-color: $bb-grey-7 !important;
      }

      &.is-invalid {
        border-color: $bb-red-light !important;
        background-color: $bb-red-light !important;
        background-size: 26px 26px !important;
        background-image: url('../../assets/svg/engine-warning.svg') !important;
      }

      &.is-valid {
        background-size: 26px 26px !important;
        background-image: url('../../assets/svg/check.svg') !important;
        background-color: $bb-grey-9 !important;
        border-color: $bb-grey-9 !important;
      }

      &.loading {
        background-image: url('../../assets/gif/loading.gif') !important;
        background-size: 15px 15px !important;
        background-position: 98% center !important;
        background-repeat: no-repeat !important;
      }

      &:disabled,
      &:disabled:focus,
      &:disabled:active {
        background-color: $bb-grey-9 !important;
        border: 1px solid $bb-grey-5 !important;
        color: $bb-grey-4 !important;
        opacity: 0.7 !important;
        background-image: none !important;

        &.no-border-rigth {
          border-right: 0 !important;
        }
        &.no-border-left {
          border-left: 0 !important;
        }
      }
    }

    .input-group-prepend {
      .input-group-text {
        border: 0 !important;
        color: $bb-grey-4 !important;
        background-color: $bb-grey-9;
        height: 38px;
        width: 38px;
        display: flex;
        justify-content: center;
        font-size: 20px !important;
        font-weight: bolder !important;

        &.is-invalid {
          background-color: $bb-red-light !important;
        }
        &.disabled {
          background-color: $bb-grey-7 !important;
          border: 1px solid $bb-grey-5 !important;
          border-right: 0 !important;
          opacity: 0.7 !important;
        }
      }
    }

    .input-group-append {
      .input-group-text {
        background-color: $bb-grey-9 !important;
        border: 0 !important;
        color: $bb-grey-2 !important;
        padding-left: 0 !important;
        background-color: $bb-grey-9 !important;

        &.is-invalid {
          background-color: $bb-red-light !important;
        }

        &.active {
          background-color: $bb-grey-7 !important;
        }

        &.is-invalid {
          background-color: $bb-red-light !important;
        }

        &.is-valid {
          background-color: $bb-grey-9 !important;
        }

        &.disabled {
          background-color: $bb-grey-7 !important;
          border: 1px solid $bb-grey-5 !important;
          border-left: 0 !important;
          color: $bb-grey-4 !important;
          opacity: 0.7 !important;
        }
      }

      span {
        cursor: pointer;
      }
    }
  }
}
