@import 'app/assets/scss/utils/variables.scss';

.crop-image {
  &__container {
    height: 100% !important;
  }
}

.crop-info {
  color: $bb-grey-3;
  margin-bottom: 20px !important;
  margin-top: 15px !important;
}
