@import 'app/assets/scss/index.scss';

.bb-popup-cancelorder {
  &__header {
    & > p {
      color: $bb-grey-2;
    }
  }

  &__body {
    & > p {
      color: $bb-grey-2;
    }

    &__question {
      margin-bottom: 25px !important;
    }
    &__explain {
      margin-bottom: 10px !important;
    }

    & > div {
      margin-bottom: 0 !important;
      & > textarea {
        height: 70px;
      }
    }
  }

  &__footer {
    display: flex;

    & > button {
      margin-right: 10px;
      @include media-breakpoint-up(lg) {
        margin-right: 25px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
