@import '../../../../assets/scss/index.scss';

.bb-synchronize-bigbuy-page {
  & > hr {
    width: 100%;
    margin: 25px 0px;
    @include media-breakpoint-up(lg) {
      margin: 30px 0px;
    }
  }
}
