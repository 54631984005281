.user-initials {
  display: inline-block;
  &__name-letter {
    font-variant-caps: small-caps;
    display: inline;
  }
  &__surname-letter {
    display: inline;
    text-transform: lowercase;
  }
  h5 {
    margin-bottom: 0;
  }
}
