@import 'app/assets/scss/index.scss';

.add-product-form {
  margin-top: 20px;
  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }

  &__container {
    > .row {
      align-items: end !important;
      @include media-breakpoint-up(lg) {
        margin-top: 25px;
      }

      .bb-textarea-form-group {
        margin-bottom: 0;
      }
    }

    &-input {
      margin-bottom: 20px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }

      .form-group {
        margin-bottom: 0 !important;
      }

      .fv-plugins-message-container {
        @include media-breakpoint-up(lg) {
          position: absolute;
          bottom: -20px;
        }
      }
    }

    &-button {
      @include media-breakpoint-up(lg) {
        button {
          width: 100% !important;
        }
      }
    }
  }
}
