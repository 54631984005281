@import 'app/assets/scss/index.scss';

.bb-notification-panel {
  &__language {
    display: inline-flex;
    align-items: center;

    &--name {
      font-weight: 500 !important;
      order: 1;
    }

    &-flag {
      order: 3;
      margin-left: 4px !important;
      margin-right: 4px !important;
      @include media-breakpoint-up(lg) {
        order: 2;
      }
    }

    &-tooltip {
      order: 2;
      @include media-breakpoint-up(lg) {
        order: 3;
      }
    }

    &-wrapper {
      margin-top: 10px;
      @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
      }

      &.margin {
        @include media-breakpoint-up(lg) {
          margin-top: 19px;
        }
      }

      &--default {
        font-size: 13px !important;
        color: $bb-grey-2 !important;
        margin: 0;
        display: inline;
        white-space: pre-wrap;
        @include media-breakpoint-up(lg) {
          font-size: 14px !important;
        }
      }
    }
  }

  & .flag-icon {
    height: 18px;
    width: 18px;
  }
}
