@import 'app/assets/scss/index.scss';

.purse {
  &-card-pending-movements {
    margin-bottom: 15px;
    @include media-breakpoint-up(md) {
      margin-bottom: 25px;
    }
  }
  &-card-movements {
    .bb-card__container__header {
      flex-direction: inherit;
      align-items: inherit;
      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
    }
    &__header {
      @include media-breakpoint-up(md) {
        display: inline-flex;
      }

      > p {
        color: $bb-secondary;
        align-self: center;
        padding: 20px 0;

        @include media-breakpoint-up(md) {
          margin-right: 25px;
          padding: 0;
        }

        span {
          font-weight: 500 !important;
        }
      }
    }

    &__body {
      margin-top: 20px;

      &-buttons {
        display: inline-flex;
        margin-top: 20px;
        @include media-breakpoint-up(md) {
          margin-bottom: 10px;
          margin-top: 0;
        }

        button {
          margin-right: 15px;
        }
      }

      .datatable-filters-tags {
        margin-bottom: 10px;
      }
    }
  }

  &-card-notifications {
    margin-top: 25px !important;

    &__active {
      &-msg {
        margin-top: 25px;
      }
    }
    &__active {
      &-form {
        margin-top: 25px;

        &-label {
          margin-bottom: 5px;
        }

        div.col-4 {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
