@import 'app/assets/scss/utils/variables.scss';

.label-container {
  display: none;
  label {
    margin-bottom: 15px;
  }
  &.show {
    display: flex;
    margin-bottom: -10px !important;
  }
}
