@import 'app/assets/scss/index.scss';

.user-notifications-dropdown {
  &.show {
    background-color: $bb-grey-9;
  }

  .bb-btn-item {
    margin-right: 0 !important;
  }

  &__container {
    max-width: 280px;
    min-width: 280px;
    width: 280px;
    padding: 0 !important;
    border-radius: 4px !important;
    box-shadow: 10px 10px 30px rgba(181, 181, 195, 0.4) !important;
    border: 0 !important;
    @include media-breakpoint-up(md) {
      max-width: 300px;
      min-width: 300px;
      width: 300px;
    }

    &-header {
      background: url('../../assets/svg/bell-on.svg');
      background-repeat: no-repeat;
      background-color: #f6e4ab;
      background-size: contain;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px 24px;
      background-position: right 35%;
      width: 343px;

      h5 {
        margin-bottom: 0 !important;
      }
    }
  }
}
