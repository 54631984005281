._bb-elipse-notification {
  height: 6px;
  width: 6px;
  border-radius: 25px;

  &.red {
    background-color: $bb-red;
  }

  &.green {
    background-color: $bb-green;
  }
}
