@import '../../../assets/scss/index.scss';

@mixin bb-pack-info-header--normal {
  min-height: 395px;
}

@mixin bb-pack-info-header--small {
  min-height: 305px;
  padding-bottom: 40px;
}

.bb-pack-info-header {
  box-shadow: 0px 3px 10px rgba(216, 216, 216, 0.6);
  border-radius: 4px 4px 0px 0px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .fa-duotone-group {
    --fa-secondary-opacity: 0.6 !important;
    --fa-primary-opacity: 0.3 !important;
  }

  h5 {
    margin-bottom: 20px;
  }

  &__box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__tag {
      color: $bb-white;
      padding: 8px 25px;
      margin-top: 35px;
      margin-bottom: 25px !important;
      border-radius: 4px;
    }
  }

  &--normal {
    @include media-breakpoint-up(lg) {
      @include bb-pack-info-header--normal;
    }

    @include media-breakpoint-down(lg) {
      @include bb-pack-info-header--small;
    }
  }

  &--small {
    @include bb-pack-info-header--small;
  }
}
