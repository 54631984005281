@import 'app/assets/scss/index.scss';

.bb-popup {
  .bb-select {
    margin-bottom: 10px;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
}
