@import 'app/assets/scss/index.scss';

.bb-checkbox {
  display: flex;
  align-items: center !important;
  padding-left: 0 !important;
  margin: 0 !important;

  .form-check-label {
    cursor: pointer;
    margin-left: 25px;
    font-size: 13px !important;
    @include media-breakpoint-up(lg) {
      font-size: 14px !important;
    }
  }

  .dropdown-tooltip {
    position: relative;
    left: 20px;
    width: 15px;
    height: 15px;
    cursor: pointer;

    path {
      fill: $bb-grey-4;
    }
  }

  input[type='checkbox'].form-check-input {
    margin: 0;
    float: none;
    flex-shrink: 0;
    border-radius: 4px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 18px;
    width: 18px;
    border: 0;
    background-color: $bb-grey-7;

    &:checked {
      background-color: $bb-secondary;
      background-image: url('../../assets/svg/check-input.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 14px;
    }
  }

  input[type='radio'].form-check-input {
    margin: 0;
    float: none;
    flex-shrink: 0;
    border-radius: 50%;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 18px;
    width: 18px;
    border: 0;
    background-color: $bb-grey-7;

    &:checked {
      background-color: $bb-secondary;
      background-image: url('../../assets/svg/radio-input-check.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 10px;
    }
  }
}
