@import 'app/assets/scss/utils/variables.scss';

.dropshipping-formatter {
  display: flex;
  align-items: center;
  gap: 5px;
  > div._bb-elipse-notification {
    margin-bottom: 3px;
  }
}
