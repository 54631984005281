@import '../../../assets/scss/index.scss';

.pagination-toolbar {
  $width: 75px;

  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__text {
    margin-left: 16px;
  }

  &__dropdown {
    > button {
      padding: 8px 30px;
      width: $width !important;
      background: $bb-grey-9 !important;
      border-radius: 6px !important;
      border: none !important;
      color: $bb-grey-2 !important;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;

      &::after {
        margin-left: 10px !important;
        color: $bb-grey-2 !important;
      }
    }

    .dropdown-menu {
      width: $width !important;
      min-width: $width !important;
      padding: 0 !important;
      border: none !important;
      outline: 1px solid rgba(0, 0, 0, 0.15);
    }

    &-menu-item {
      width: $width !important;
      justify-content: center;
      background-color: $bb-white !important;
      cursor: pointer;
      &:first-child {
        border-top-left-radius: 4px !important;
        border-top-right-radius: 4px !important;
      }

      &:last-child {
        border-bottom-right-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
      }

      &.active {
        background-color: $bb-secondary !important;
        color: $bb-white !important;
        font-weight: 500 !important;
      }

      &:hover {
        background-color: $bb-secondary-light !important;
        color: $bb-secondary !important;
      }
    }
  }
}
