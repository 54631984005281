@import 'app/assets/scss/index.scss';

.modal-language-body.modal {
  display: flex !important;
  justify-content: center !important;

  .modal-dialog {
    max-width: 325px !important;
    @include media-breakpoint-up(lg) {
      max-width: 705px !important;
    }

    .modal-languages-title {
      padding: 15px 0;
      @include media-breakpoint-up(lg) {
        padding: 25px 0;
      }
    }

    .modal-languages {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
      @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(4, 1fr);
        gap: 26px;
      }

      &__container {
        display: inline-flex;
        padding: 10px;
        box-shadow: 0px 2px 6px 2px rgba(126, 130, 153, 0.15);
        border-radius: 4px;
        align-items: center;
        grid-column: auto / span 1;

        &-checkbox {
          margin-right: 10px;
          input[type='checkbox'].bb-checkbox {
            cursor: pointer;
            background-color: $bb-grey-7;
            border-color: $bb-grey-7;

            &:checked {
              background-color: $bb-secondary;
              border-color: $bb-secondary;
            }
          }
        }
        &-image {
          width: 17px;
          margin-right: 10px;
          svg {
            border-radius: 4px;
          }
        }
      }
    }
  }

  .modal-footer {
    padding-bottom: 15px;
    padding-top: 15px;
    gap: 15px;
    @include media-breakpoint-up(lg) {
      padding: 25px 30px;
      gap: 25px;
    }
  }

  .modal-body {
    @include media-breakpoint-up(lg) {
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 25px;
    }
  }
  .modal-header {
    padding-bottom: 15px;
    padding-top: 15px;
    @include media-breakpoint-up(lg) {
      padding: 25px 30px;
    }

    .modal-title {
      cursor: pointer;
      width: 100%;
      display: inline-flex;
      justify-content: inherit;
    }
  }
}
