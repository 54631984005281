@import 'app/assets/scss/utils/variables.scss';

.menu-link {
  padding: 0 25px !important;
}

.menu-text {
  color: #7e8299 !important;
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.menu-item-active > .menu-link.active > .menu-text {
  color: white !important;
}

.menu-item {
  .active {
    i {
      color: $bb-primary !important;
    }
  }
  &:hover {
    i {
      color: $bb-primary !important;
    }
  }
}
