@import '../../assets/scss/index.scss';

.bb-chart {
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 10px 10px 30px rgba(181, 181, 195, 0.4);
  border-radius: 4px;
  padding: 20px 15px;
  @include media-breakpoint-up(lg) {
    padding: 25px 30px;
  }

  & > div {
    & > div {
      .apexcharts-title-text {
        fill: $bb-grey-2;
        font-size: 20px !important;
        line-height: 23px !important;
        font-family: Roboto !important;
        font-weight: bold !important;
      }

      .apexcharts-custom-axis-label {
        fill: $bb-grey-4;
        font-size: 14px !important;
        line-height: 16px !important;
        font-family: Roboto !important;
        font-weight: 400 !important;
      }
    }
  }
}
