@import 'app/assets/scss/index.scss';

.bb-payment-methods-paypal {
  &__paypal-items {
    width: 100%;

    &--header {
      color: #464e5f;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      @include media-breakpoint-up(md) {
        margin-bottom: 25px;
      }

      & > p {
        font-size: 14px !important;

        @include media-breakpoint-up(md) {
          font-size: 16px !important;
        }
      }
    }
  }
}
