@import 'app/assets/scss/index.scss';
@import 'app/components/ModalWrongPack/WrongPack.scss';

.modal-wrong-pack.modal {
  display: flex !important;
  justify-content: center !important;
  padding-right: 0 !important;

  .modal-dialog {
    max-width: 325px !important;
    @include media-breakpoint-up(lg) {
      max-width: 500px !important;
      width: 500px;
    }

    .modal-header {
      padding: 15px;
      @include media-breakpoint-up(lg) {
        padding: 25px 30px;
      }
      .modal-title {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;

        svg.svg-inline--fa {
          cursor: pointer;
          opacity: 1 !important;
        }
      }
    }

    .modal-footer {
      padding: 15px;
      @include media-breakpoint-up(lg) {
        padding: 25px 30px;
      }
    }
  }
}
