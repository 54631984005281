@import '../../../../../assets/scss/index.scss';

.bb-wholesaler-pack-without-pack {
  &__p-sub-title {
    margin-bottom: 20px !important;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;

    button {
      margin-top: 20px;
      white-space: nowrap;
      margin-right: 20px;
    }
  }

  @include media-breakpoint-up(md) {
    &__p-sub-title {
      padding-top: 5px;
    }

    button {
      margin-top: 20px;
    }
  }

  @include media-breakpoint-down(md) {
    button {
      margin-top: 15px;
    }
  }
}
