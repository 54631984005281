@import 'app/assets/scss/index.scss';

.collection-address-form {
  $marginBottomInputsMobile: 20px;
  $marginBottomInputsDesktop: 25px;

  &.disabled {
    .collection-address-form__textarea:last-child {
      margin-bottom: 0 !important;
    }
  }

  margin-top: 20px;
  @include media-breakpoint-up(lg) {
    margin-top: 25px;
  }

  .row > div {
    margin-bottom: 5px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 10px;
    }
  }

  &__textarea {
    margin-bottom: $marginBottomInputsMobile !important;
    @include media-breakpoint-up(lg) {
      margin-bottom: marginBottomInputsDesktop !important;
    }
  }

  &__select {
    margin-bottom: $marginBottomInputsMobile !important;
    @include media-breakpoint-up(lg) {
      margin-bottom: marginBottomInputsDesktop !important;
    }
  }

  &__radio {
    display: flex;
    gap: 30px;
    margin-bottom: $marginBottomInputsMobile !important;
    @include media-breakpoint-up(lg) {
      gap: 54px;
      align-items: center;
    }
  }

  &__button {
    margin-top: 5px;
    flex-direction: row-reverse;
    display: flex;
  }

  &__directions {
    display: contents;
    .direction-title {
      margin-top: 24px;
      &:first-of-type {
        margin-top: 14px;
      }
    }
  }
}
