@import 'app/assets/scss/index.scss';

.devolutions-add {
  &__card {
    &-table {
      margin-top: 20px;
      @include media-breakpoint-up(lg) {
        margin-top: 25px;
      }

      .summary {
        padding: 25px;
      }
    }

    &-notification {
      margin-top: 20px;
      max-width: 100%;
      padding: 18px 15px;
      @include media-breakpoint-up(md) {
        margin-top: 25px;
        padding: 25px 30px;
      }
      .bb-notification__body {
        margin-bottom: 0 !important;
      }

      ul {
        padding: 0;
        padding-left: 24px;
        margin-bottom: 0;

        li:first-child {
          @include media-breakpoint-down(lg) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  &__rma-detail {
    margin-top: 15px;
    @include media-breakpoint-up(lg) {
      margin-top: 25px;
    }

    .bb-card__container__header {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 25px;
        flex-direction: row;
      }
    }
  }
}
