@import 'app/assets/scss/index.scss';

.prestashop-form {
  background: $bb-green-light;
  box-shadow: 10px 10px 30px rgba(181, 181, 195, 0.4);
  border-radius: 4px;
  align-items: flex-start;

  &__container {
    columns: 3 200px;
    column-gap: 8rem;
  }

  &__item {
    display: inline-block;
    width: 100%;
  }

  &_titleForm {
    color: $bb-green;
    margin-bottom: 20px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 25px;
    }
  }

  &__title {
    color: $bb-green;
    margin-bottom: 20px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 25px;
    }
  }

  &__content {
    margin: 25px 0;
    &__title {
      color: $bb-grey-2;
      margin-bottom: 20px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 25px;
      }
    }
    &__text {
      color: $bb-grey-3;
      margin-top: 7px;
      border-bottom: 2px dotted $bb-green;
      @include media-breakpoint-up(lg) {
        margin-top: 10px;
      }
    }
  }
}
