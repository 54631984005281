@import 'app/assets/scss/index.scss';

.bb-payment-methods {
  &-bigbuy-page {
    & hr {
      margin: 0 !important;
    }
    & .bb-formcard__body {
      @include media-breakpoint-up(sm) {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
  &-notification {
    margin-top: 25px;

    & .bb-notification {
      &__icon {
        display: flex;
        align-items: center;

        @include media-breakpoint-up(md) {
          padding-left: 15px;
        }
      }
      &__body {
        margin-bottom: 0;
      }
    }
  }
}
