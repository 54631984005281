@import '../../assets/scss/index.scss';

.bb-textarea-form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 1.75rem;

  & > div {
    & > label {
      color: $bb-grey-2;
    }
  }

  &__textarea {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: $bb-grey-9;
    border-radius: 4px;
    border: none;
    resize: none;
    padding: 13px 15px 13px 10px;
    color: $bb-grey-2;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    &--disabled {
      border: 1px solid $bb-grey-5;
      color: $bb-grey-4;
      background-color: $bb-grey-9;
      opacity: 0.7 !important;
    }

    &--error {
      background: $bb-red-light !important;
      border-color: $bb-red-light !important;
      background-color: $bb-red-light !important;
      background-size: 26px 26px !important;
      background-image: url('../../assets/svg/engine-warning.svg') !important;
      background-repeat: no-repeat !important;
      background-position-x: calc(100% - 9px) !important;
      background-position-y: calc(100% - 9px) !important;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &:focus {
      outline: none !important;
      background: $bb-grey-7;
    }

    &:focus-visible {
      outline: none !important;
    }

    &::-webkit-input-placeholder {
      color: $bb-grey-4;
    }

    &:-moz-placeholder {
      color: $bb-grey-4;
    }

    &::-moz-placeholder {
      color: $bb-grey-4;
    }

    &:-ms-input-placeholder {
      color: $bb-grey-4;
    }

    &::placeholder {
      color: $bb-grey-4;
    }
  }
}
