@import 'app/assets/scss/index.scss';

.order-view {
  .order-info-card {
    margin-bottom: 15px;
    @include media-breakpoint-up(md) {
      height: 100%;
    }

    .col-md-12 {
      display: inline-flex !important;
      margin-bottom: 7px;

      span {
        font-weight: normal;
      }
    }

    .margin-bottom {
      @include media-breakpoint-up(md) {
        margin-bottom: 25px;
      }
    }
  }

  .order-detail {
    @include media-breakpoint-up(md) {
      margin-top: 25px;
    }

    &__button-container {
      display: inline-flex;

      .attach-files-modal {
        margin-left: 5px;
      }
    }
    .bb-card__container__header {
      align-items: flex-start;
      @include media-breakpoint-up(lg) {
        align-items: center;
      }
      &__title {
        margin-bottom: 10px;
      }
    }
  }

  .invoice,
  .delivery-note {
    margin-top: 15px;
    @include media-breakpoint-up(md) {
      margin-top: 25px;
    }
  }

  .bb-card__container__body {
    margin-top: 20px;

    @include media-breakpoint-up(md) {
      margin-top: 25px;
    }
  }
}
