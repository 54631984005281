@import 'app/assets/scss/index.scss';

.bb-multichannel__main-card {
  margin-bottom: 25px !important;
  &-title {
    margin-top: 5px;
    margin-bottom: 15px !important;
    font-size: 14px !important;
    color: $bb-grey-2;
    line-height: 16.41px !important;
    font-weight: 700 !important;

    @include media-breakpoint-up(md) {
      margin-top: 25px !important;
      font-size: 16px !important;
      line-height: 18.75px !important;
    }
  }

  &-list {
    @include media-breakpoint-up(md) {
      max-height: 160px;
      max-width: fit-content;

      .icons-list {
        &__item {
          margin-right: 35px;
          height: 34px;

          &-icon {
            height: auto;
            width: auto;
          }
        }
      }
    }
  }

  &-subtitle {
    font-size: 14px !important;
    color: $bb-grey-2;
    line-height: 16.41px !important;
    margin-bottom: 15px !important;

    @include media-breakpoint-up(md) {
      font-size: 16px !important;
      line-height: 18.75px !important;
    }
  }

  &-content {
    font-size: 12px !important;
    color: $bb-grey-3;
    line-height: 14.06px !important;
  }

  > .bb-card {
    background-image: url('../../../assets/png/multichannel/main-background-mobile.png') !important;
    background-repeat: no-repeat !important;
    background-position: 100% 100% !important;
    background-size: contain !important;
    min-height: 328px;

    @include media-breakpoint-up(md) {
      background-size: contain !important;
      background-image: url('../../../assets/png/multichannel/main-background.png') !important;
      background-position: 100% 0px !important;
    }
  }
}

.limit-width {
  @include media-breakpoint-up(md) {
    max-width: min(679px, 70%) !important;
  }
}
