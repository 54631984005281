.fv-plugins-message-container-multiple {
  position: absolute;
  overflow: hidden;
  margin-top: 0.25rem;

  .fv-help-block-multiple {
    width: 100vw;
    color: $danger;
    font-size: 0.9rem;
    font-weight: 400;
  }
}

.fv-plugins-message-container{
  .fv-help-block{
    color: $danger;
  }
}