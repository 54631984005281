@import 'app/assets/scss/index.scss';

.data-table-components {
  display: inline-flex;
  width: 100%;

  &__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .pagination {
      width: 100%;
    }
  }
}
