@import 'app/assets/scss/utils/variables.scss';

.chip{
  border-radius: 4px;
  color: $bb-white;
  height: 19px;
  padding: 0 8px;
  font-size: 14px !important;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  align-items: center !important;
  justify-content: center !important;
  display: flex;
  width: fit-content;
  overflow: hidden;
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.disabled{
    background-color: #CCCFD3 !important;
  }
}