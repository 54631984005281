@import '../../assets/scss/index.scss';

#error-page {
  text-align: center;

  > img {
    width: 100%;
    margin-top: 60%;
    @include media-breakpoint-up(lg) {
      margin-top: -80px;
    }
  }

  > p {
    margin-top: 12px;
    @include media-breakpoint-up(lg) {
      margin-top: -45px;
    }
  }
}
