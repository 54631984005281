@import '../../../../../assets/scss/index.scss';

.bb-synchronize-ftp {
  @include media-breakpoint-up(sm) {
    button {
      margin-top: 15px;
    }

    &__row-info {
      padding-top: 30px;
    }
  }

  @include media-breakpoint-down(sm) {
    button {
      margin-top: 20px;
    }

    &__row-info {
      padding-top: 20px;
    }

    &__p-info {
      margin-bottom: 7px !important;
    }
  }

  @include media-breakpoint-up(lg) {
    p.big {
      margin-bottom: 25px !important;
    }
  }

  @include media-breakpoint-down(lg) {
    p.big {
      margin-bottom: 20px !important;
    }
  }
}
