@import 'app/assets/scss/index.scss';

.product-formatter {
  display: inline-flex;
  align-items: center;
  img {
    height: 32px;
    width: 32px;
    border-radius: 6px;
    margin-right: 18px;
  }
  &__not-image {
    margin-right: 18px;
    width: 32px;
    min-width: 32px;
    height: 32px;
    border-radius: 6px;
    background-color: $bb-grey-9;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
