@import 'app/assets/scss/index.scss';

.bb-payment-methods-credit-cards {
  &__credit-cards-items {
    width: 100%;

    &--header {
      color: $bb-grey-2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      @include media-breakpoint-up(md) {
        margin-bottom: 25px;
      }

      & > p {
        font-size: 14px !important;

        @include media-breakpoint-up(md) {
          font-size: 16px !important;
        }
      }
    }

    &--content {
      .bb-separator--dash {
        display: none;
        @include media-breakpoint-up(md) {
          display: block;
          margin: 24px 0 !important;
        }
      }
    }
  }

  &__p-info {
    color: $bb-grey-2;
    font-size: 14px !important;
    margin-bottom: 20px !important;

    @include media-breakpoint-up(md) {
      font-size: 16px !important;
      margin-bottom: 0 !important;
    }
  }
}
