@import '../../assets/scss/index.scss';

.bb-switch {
  display: flex;
  flex-direction: column;

  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__label {
      position: relative;
      display: inline-block;
      margin-bottom: 0px;

      &__input {
        position: absolute;
        left: -9999px;
        top: -9999px;

        &:checked + span {
          background-color: $bb-secondary;

          &:before {
            left: calc(100% - 2px);
            transform: translateX(-100%);
          }
        }
      }

      &__circle {
        display: flex;
        position: relative;
        width: 32px;
        height: 20px;
        border-radius: 20px;
        background-color: $bb-grey-7;
        transition: background-color 0.2s;
        cursor: pointer;

        &:before {
          content: '';
          position: absolute;
          top: 2px;
          left: 2px;
          width: 16px;
          height: 16px;
          border-radius: 20px;
          transition: 0.2s;
          background-color: $bb-white;
        }
      }
    }

    &__text {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5px;

      & > .label-container.show {
        display: flex;
        margin-bottom: 0px !important;
        & > label {
          margin-bottom: 0px;
        }
      }
    }
  }
}
