.course-card-discount {
  color: #73af00;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 4px;
  align-self: stretch;
  flex-wrap: wrap;
  span {
    text-decoration: line-through;
  }
}
