@import '../../assets/scss/index.scss';

.bb-separator {
  &--dash {
    border: 1px dashed $bb-grey-6;
  }

  &--solid {
    border-top: 1px solid $bb-grey-6;
  }
}
