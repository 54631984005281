@import 'app/assets/scss/index.scss';

.bb-payment-methods-paypal {
  &__row-info {
    align-items: center;
    padding: 20px 0;

    @include media-breakpoint-up(md) {
      padding: 30px 0;
    }
  }

  &__col-info {
    > button > p {
      font-size: 14px !important;
    }
    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}
