@import 'app/assets/scss/index.scss';

.bb-shops {
  display: block;
  &__data-table {
    &__header {
      padding-bottom: 0 !important;
    }
    &__wrapper {
      margin-bottom: 25px;
    }
    &__column-header {
      & th {
        & label {
          color: $bb-grey-4;
          font-size: 12px !important;
          margin-bottom: 0;
        }
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__header {
      padding: 0px;
      display: flex;
      align-items: center;
      @include media-breakpoint-up(md) {
        padding: 25px 30px;
      }
      &__icon {
        background-color: $bb-secondary-light;
        width: 32px;
        height: 32px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__title {
        margin-left: 10px;
        color: $bb-grey-2 !important;
      }
    }

    &__cell {
      color: $bb-grey-2;

      &.warning {
        color: $bb-red;
      }

      &.light-warning {
        color: $bb-red-medium;
      }

      &.light-grey {
        color: $bb-grey-3;
      }

      &--wrapper {
        display: flex;
        align-items: center;

        &.order-id {
          min-width: 90px;
        }
      }

      &--warning-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 26px;
        width: 26px;
        margin-right: 8px;
      }

      &--tag {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 7px 15px;
        text-align: center;
        border-radius: 4px;
        height: 32px;

        & label {
          font-size: 12px !important;
          font-weight: 500 !important;
          margin-bottom: 0;
        }
      }

      &__action-icon {
        background-color: $bb-grey-8;
        width: 32px;
        height: 32px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        & a {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  & .bb-formcard__body {
    padding: 0;
    @include media-breakpoint-up(md) {
      padding: 25px 30px;
    }
  }

  & .bb-searchbar {
    & input::placeholder {
      color: $bb-grey-4;
    }
  }

  & .pagination__container {
    display: none;
  }
}
