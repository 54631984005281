@import 'app/assets/scss/index.scss';

.devolutions-notification {
  &__text {
    margin-top: 16px;
    margin-bottom: 16px !important;
    @include media-breakpoint-up(md) {
      margin-top: 24px;
      margin-bottom: 24px !important;
    }

    a {
      text-decoration: underline;
    }
  }

  &__button-container {
    margin-top: 16px;
    width: 100%;
    display: flex;
    gap: 16px;
    @include media-breakpoint-up(md) {
      margin-top: 24px;
      gap: 24px;
    }
  }
}
