@import '../../../../../../assets/scss/index.scss';

.customer-type {
  margin-top: 10px;
  margin-bottom: 40px;
  @include media-breakpoint-up(lg) {
    margin-bottom: 25px;
  }

  &__container {
    display: flex;
    justify-content: space-between;

    &-element {
      display: flex;
      flex-direction: column;
      align-items: center;

      > p {
        margin-top: 21px;
        @include media-breakpoint-up(lg) {
          margin-top: 10px;
        }
      }
    }
  }
}
