@import 'app/assets/scss/utils/variables.scss';

.bb-tag {
  display: flex;
  max-width: max-content;
  border-radius: 4px;
  cursor: pointer;

  &__grey {
    color: $bb-grey-3;
    background-color: $bb-grey-7;
    &:hover {
      color: $bb-white;
      background-color: $bb-grey-4;
      path {
        fill: $bb-white;
      }
    }
    > svg {
      path {
        fill: $bb-grey-3;
      }
    }
  }
  &__red {
    color: $bb-red;
    background-color: $bb-red-medium;
    &:hover {
      color: $bb-white;
      background-color: $bb-red;
      path {
        fill: $bb-white;
      }
    }
    > svg {
      path {
        fill: $bb-red;
      }
    }
  }
  &__blue {
    color: $bb-secondary;
    background-color: $bb-secondary-medium;
    &:hover {
      color: $bb-white;
      background-color: $bb-secondary;
      path {
        fill: $bb-white;
      }
    }
    > svg {
      path {
        fill: $bb-secondary;
      }
    }
  }
  &__yellow {
    color: $bb-primary-dark;
    background-color: $bb-primary-light;
    &:hover {
      color: $bb-white;
      background-color: $bb-primary;
      path {
        fill: $bb-white;
      }
    }
    > svg {
      path {
        fill: $bb-primary-dark;
      }
    }
  }
  &__green {
    color: $bb-green;
    background-color: $bb-green-medium;
    &:hover {
      color: $bb-white;
      background-color: $bb-green;
      path {
        fill: $bb-white;
      }
    }
    > svg {
      path {
        fill: $bb-green;
      }
    }
  }
  &__purple {
    color: $bb-purple;
    background-color: $bb-purple-medium;
    &:hover {
      color: $bb-white;
      background-color: $bb-purple;
      path {
        fill: $bb-white;
      }
    }
    > svg {
      path {
        fill: $bb-purple;
      }
    }
  }

  &__regular {
    padding: 9px 10px 9px 15px;
  }
  &__small {
    padding: 7px 15px;
  }
  > div {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
  > svg {
    position: relative;
    top: 3px;
    width: 8px;
    height: 8px;
    margin-left: 10px;
    cursor: pointer;
  }
}
