@import '../../assets/scss/index.scss';

.bb-wallet-card {
  display: flex;
  flex-direction: column;
  background: $bb-white;
  border-radius: 4px;
  box-shadow: 10px 10px 30px rgba(181, 181, 195, 0.4);
  padding: 20px 15px;
  @include media-breakpoint-up(lg) {
    padding: 25px 30px;
  }
  z-index: 9;
  position: relative;
  cursor: default;

  &__image {
    display: flex;
    position: absolute;
    top: 0px;
    left: 0px;
    background: $bb-secondary;
    width: 100%;
    height: 150px;
    border-radius: 4px 4px 0px 0px;
    overflow: hidden;

    &--default {
      @include media-breakpoint-up(lg) {
        height: 170px;
      }
      & > img {
        display: flex;
        width: 100%;
        height: 200px;
        margin-top: 32px;
        z-index: 9;
      }
    }

    &--medium {
      @include media-breakpoint-up(lg) {
        height: 338px;
      }
      & > img {
        display: flex;
        width: 100%;
        height: 246px;
        margin-top: 92px;
        z-index: 9;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
    & > h5 {
      color: $bb-white;
    }
  }

  &__center {
    display: flex;
    flex-direction: column;
    background: $bb-grey-9;
    border-radius: 4px;
    padding: 8px 32px;
    @include media-breakpoint-up(lg) {
      padding: 10px 17px;
    }
    z-index: 99;

    &--default {
      margin-top: 45px;
    }

    &--medium {
      margin-top: 150px;
    }

    & > p {
      margin: 0px;
      color: $bb-grey-3;
    }

    & > h2 {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $bb-grey-2;
    }
  }

  &__bottom {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &--default {
      & > button {
        display: flex;
        margin-top: 25px;
      }
    }

    &--medium {
      & > button {
        display: flex;
        &:nth-child(odd) {
          margin-top: 54px;
        }
        &:nth-child(even) {
          margin-top: 25px;
        }
      }
    }

    & > .bb-timeline {
      display: flex;
      margin-top: 25px;
    }
  }
}
