@import 'app/assets/scss/index.scss';

.shop-shipping-process-shopify-wrapper {
  margin-bottom: 15px;
}

.shopify-form {
  display: flex;
  flex-direction: column;
  background: $bb-white;
  box-shadow: 10px 10px 30px rgba(181, 181, 195, 0.4);
  border-radius: 4px;

  &__body {
    margin: 0 !important;
    justify-content: center;
    @include media-breakpoint-up(lg) {
      padding: 5px 30px;
      padding-top: 30px;
    }
  }

  & > hr {
    width: 100%;
    margin: 0;
  }
}
