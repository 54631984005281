@import 'app/assets/scss/index.scss';

.shopify-form {
  &__process {
    width: 100%;
    margin: 0 !important;
    justify-content: center;

    &-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 0;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        justify-content: space-between;
        padding: 30px 45px !important;
      }

      .collapse-steps.show {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;

        @include media-breakpoint-up(lg) {
          width: 100%;
          flex-direction: row !important;
          justify-content: space-between !important;
          padding: 30px 45px !important;
        }
      }

      .link {
        color: $bb-secondary;
        text-decoration: underline;
        margin: 24px 0 !important;
      }

      .button-view-more {
        width: 100%;
        white-space: nowrap;
      }
    }

    &-arrow {
      transform: rotate(90deg);

      @include media-breakpoint-up(lg) {
        transform: none;
      }
    }
  }
}
