@import 'app/assets/scss/index.scss';

.wrong-pack {
  .modal-wrong-pack__title {
    margin-bottom: 15px !important;
    @include media-breakpoint-up(lg) {
      margin-bottom: 10px !important;
    }
  }

  .modal-wrong-pack__text {
    margin-bottom: 15px !important;
    @include media-breakpoint-up(lg) {
      margin-bottom: 25px !important;
    }
  }

  .modal-wrong-pack__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    div:first-child {
      margin-bottom: 15px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
