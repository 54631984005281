@import 'app/assets/scss/utils/variables.scss';

.bb-search__container {
  display: flex;

  .bb-searchbar {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 9px 10px;
    background: $bb-white;
    box-shadow: 0px 3px 10px rgba(216, 216, 216, 0.6);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    &--big {
      min-height: 60px;
    }

    &--normal {
      height: 38px;
    }

    &__search-icon {
      width: 24px;
      height: 24px;
      path {
        fill: $bb-secondary;
      }
      margin-right: 4px;
    }
    &__close-icon {
      width: 14px;
      height: 14px;
      path {
        fill: $bb-grey-4;
      }
      cursor: pointer;
    }
    &__input {
      display: flex;
      width: 100%;
      border: none;
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: $bb-grey-2;

      &:focus {
        outline: none;
      }
      &::placeholder {
        color: $bb-grey-4;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: $bb-grey-4;
      }

      &::-ms-input-placeholder {
        color: $bb-grey-4;
      }
    }
  }
  .bb-btn {
    border-top-left-radius: unset !important;
    border-bottom-left-radius: unset !important;
  }
}
