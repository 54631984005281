@import '../../assets/scss/index.scss';

.bb-connector-list {
  display: flex;
  flex-direction: column;
  background: $bb-white;
  box-shadow: 10px 10px 30px rgba(181, 181, 195, 0.4);
  border-radius: 4px;
  padding: 15px;
  @include media-breakpoint-up(lg) {
    padding: 25px 30px;
  }

  &__container {
    & > h5 {
      color: $bb-grey-2;
      margin: 0px 0px 25px 0px;
    }

    &__card {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
