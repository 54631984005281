@import '../../assets/scss/index.scss';

.bb-dashboard {
  & > div {
    margin-bottom: 15px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 25px;
    }
  }
  .bb-wallet-card {
    height: 100%;
  }
}

.notifications-alert-container {
  margin-bottom: 15px;
  @include media-breakpoint-up(lg) {
    margin-bottom: 25px;
  }
}
