@import 'app/assets/scss/index.scss';

.bb-topbar {
  .user-profile-dropdown {
    .topbar-item {
      > .bb-btn-item {
        width: auto !important;
      }
    }
  }

  div {
    > .bb-btn-item {
      position: relative;
      height: 38px !important;
      width: 38px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 3px;
      border-radius: 4px;
      padding: 0 6px;

      &:hover {
        background-color: $bb-grey-9;
        cursor: pointer;
      }

      @include media-breakpoint-up(md) {
        height: 45px !important;
        width: 45px !important;
        margin-right: 7px;
      }

      .bb-notification-badge {
        position: absolute;
        top: 3px;
        right: 1px;
      }
    }
  }
}
