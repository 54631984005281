@import '../../assets/scss/index.scss';

.bb-courses {
  &-body {
    display: flex !important;
    gap: 16px;
    flex-wrap: wrap;
    padding-top: 25px;
    justify-content: center;
    align-items: center;
    align-content: center;

    @include media-breakpoint-up(xl) {
      align-items: flex-start;
      align-content: flex-start;
      justify-content: start;
    }

    .course-card {
      width: 252px;
      &-body {
        height: 321px;
      }
    }
  }
}
