@import 'app/assets/scss/index.scss';

.category-list {
  &__section {
    border: 1px dashed $bb-grey-6;
    padding: 20px 15px;

    &-title {
      margin-bottom: 15px !important;
    }

    &-children {
      display: inline-flex;
      width: 100%;
      @include media-breakpoint-up(lg) {
        width: 45%;
      }
      &:first-child {
        margin-bottom: 25px;
        @include media-breakpoint-up(lg) {
          margin-bottom: 0 !important;
          margin-right: 25px;
        }
      }

      .icon {
        margin-right: 15px;
      }

      .description {
        .date {
          color: $bb-grey-4;
          margin-top: 6px;
        }
      }
    }
  }
}
