.bb-promo-card {
  display: flex;
  background-size: cover;
  border-radius: 4px;
  flex-direction: column;
  width: 100%;
  background-color: black;

  & img {
    object-fit: cover;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &__links {
    position: absolute;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: calc(100% - 30px);
    padding: 24px 32px;

    &--multiple {
      width: 100%;
    }

    & .bb-btn {
      border-radius: 2px !important;
      &--outline {
        &--primary {
          border: 1.5px solid #f2b801 !important;
        }
      }
    }
  }
}
