@import '../../assets/scss/index.scss';

.bb-btn {
  &.reset-styles {
    border: 0 !important;
    background-color: transparent !important;
  }
}

@include block('bb-btn') {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 4px !important;
  text-align: center !important;
  border: none !important;
  padding: 0 15px !important;

  i {
    font-size: unset !important;
    padding-right: unset !important;
  }

  &__text {
    font-size: 14px !important;
  }

  @include modifier('disabled') {
    opacity: 0.5;
    pointer-events: none !important;
    cursor: not-allowed !important;
  }

  @include element('default') {
    color: $bb-secondary !important;
    background-color: $bb-grey-9 !important;

    &:hover,
    :active {
      background: $bb-grey-6 !important;
      transition: all 0.3s;

      svg {
        color: $bb-secondary !important;
        --color: $bb-secondary !important;
      }
    }
  }

  @include element('outline') {
    color: $bb-grey-2 !important;
    background-color: $bb-white !important;
    border: 1px solid $bb-grey-2 !important;

    &:hover,
    :active {
      background: $bb-grey-2 !important;
      color: $bb-white !important;

      svg {
        color: $bb-white !important;
        --color: $bb-white !important;
      }
    }
  }

  @include element('primary') {
    color: $bb-grey-2 !important;
    background-color: $bb-primary !important;
    border: none !important;

    &:hover,
    :active {
      background: $bb-primary-dark !important;
    }
  }

  @include element('secondary') {
    color: $bb-white !important;
    background-color: $bb-secondary !important;
    border: none !important;

    &:hover,
    :active {
      background: $bb-secondary-dark !important;
    }
  }

  @include element('tertiary') {
    color: $bb-grey-2 !important;
    background-color: transparent !important;
    border: none !important;

    &:hover,
    :active {
      background: $bb-grey-9 !important;
      color: $bb-secondary !important;

      svg {
        color: $bb-secondary !important;
        --color: $bb-secondary !important;
      }
    }
  }

  // TODO: Ver si añadir el color #303437
  @include element('outline') {
    color: #50565b !important;
    background-color: transparent !important;
    border: 1.5px solid #50565b !important;
    border-radius: 2px;

    &:hover,
    :active {
      background: #303437 !important;
      color: $bb-white !important;

      svg {
        color: #303437 !important;
        --color: #303437 !important;
      }
    }
  }

  @include element('icon') {
    border: none !important;

    p {
      margin-left: 7px;
    }

    @include modifier('huge') {
      @extend .bb-btn--huge;
      width: 45px;
    }

    @include modifier('big') {
      @extend .bb-btn--big;
      width: 38px;
    }

    @include modifier('medium') {
      width: 32px;
      @extend .bb-btn--medium;
    }

    @include modifier('small') {
      width: 28px;
      @extend .bb-btn--small;
    }
  }

  // btn sizes
  @include modifier('huge') {
    height: 45px;
  }

  @include modifier('big') {
    height: 38px;
  }

  @include modifier('medium') {
    height: 32px;
  }

  @include modifier('small') {
    height: 28px;
  }
}
