@import '../../assets/scss/index.scss';

.bb-formcard {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: $bb-white;
  box-shadow: 10px 10px 30px rgba(181, 181, 195, 0.4);
  border-radius: 4px;

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 15px;
    @include media-breakpoint-up(lg) {
      align-items: center;
      flex-direction: row;
      padding: 25px 30px;
    }

    & > p {
      display: inline-flex;
      flex-grow: 1;
      color: $bb-grey-2;
    }

    &__buttons {
      display: flex;
      margin-top: 20px;
      @include media-breakpoint-up(lg) {
        margin-top: 0px;
      }

      & > button {
        margin-right: 15px;
        @include media-breakpoint-up(lg) {
          margin-right: 7px;
        }
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
  & > hr {
    display: flex;
    width: 100%;
    margin: 0px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 20px 15px;
    @include media-breakpoint-up(lg) {
      padding: 25px 30px;
    }
  }
}
