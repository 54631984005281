@import 'app/assets/scss/index.scss';

.shopify-form {
  &__header {
    &__process {
      &__step {
        margin: 15px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
          margin: 0;
        }
      }
      &__text {
        margin-top: 15px;

        @include media-breakpoint-up(lg) {
          margin-top: 11px;
        }
      }
    }
  }
}
