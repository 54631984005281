@import 'app/assets/scss/index.scss';

.menu-layout {
  &__container {
    background: $bb-white;
    box-shadow: 0px 6px 10px 4px rgba(126, 130, 153, 0.15);
    border-radius: 4px;
    padding: 25px;
    gap: 7px;
    display: flex;
    flex-direction: column;

    margin-bottom: 15px;
    @include media-breakpoint-up(md) {
      display: flex !important;
      margin-bottom: 0;
    }

    > .item {
      display: flex;
      flex-direction: row;
      padding: 16px 22px;
      gap: 10px;
      color: $bb-grey-3 !important;

      &.active {
        color: $bb-secondary !important;
        background-color: $bb-grey-9;
      }
    }
  }
}
