@import 'app/assets/scss/index.scss';

.bb-selected-layer {
  position: relative;

  &__layer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;

    input[type='checkbox'] {
      margin-left: 5% !important;
      margin-top: 5% !important;
    }
  }

  &__children {
    display: block;
    width: 100%;
    height: auto;
  }
}
