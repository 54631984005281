@import '../../assets/scss/index.scss';

.bb-ecommerce-connector-card {
  box-sizing: border-box;
  background: $bb-white;
  box-shadow: 0px 3px 10px rgba(216, 216, 216, 0.6);
  border-radius: 4px;
  padding: 10px 15px;
  @include media-breakpoint-up(lg) {
    padding: 25px;
  }

  &--border {
    border: 2px solid $bb-green-medium;
  }

  &__container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    @include media-breakpoint-up(lg) {
      justify-content: space-between;
    }
    align-items: center;

    &--hidden-icon {
      display: flex;
      width: 33%;
      height: 100%;
      margin-right: 10px;
    }

    &__chevron-icon {
      display: flex;
      width: 15px;
      height: 15px;
      margin-right: 10px;
      transition: 0.3s;
      cursor: pointer;
      path {
        fill: $bb-green;
      }

      &--up {
        transform: rotate(-180deg);
      }
    }

    &__images {
      display: flex;
      flex-grow: 1;
      height: 100%;
      justify-content: flex-start;
      align-items: center;

      & > img {
        max-height: 25px;
        @include media-breakpoint-up(lg) {
          max-height: 35px;
        }
        margin-right: 10px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    &__text {
      display: none;
      text-transform: capitalize;
      @include media-breakpoint-up(lg) {
        display: flex;
        flex-grow: 1;
        height: 100%;
        align-items: center;
        & > p {
          color: $bb-green;
        }
      }
    }

    &__button {
      display: flex;
      flex-grow: 1;
      height: 100%;
      justify-content: flex-end;
      align-items: center;

      & > button {
        height: 28px !important;
        @include media-breakpoint-up(lg) {
          height: 32px !important;
        }
      }
    }
  }

  &__collapse {
    display: flex;
    position: relative;
    flex-direction: column;
    top: 10px;
    left: -15px;
    width: calc(100% + 30px);
    @include media-breakpoint-up(lg) {
      top: 25px;
      left: -25px;
      width: calc(100% + 50px);
    }
    height: 100%;

    &__texts {
      display: flex;
      padding: 10px 15px;
      @include media-breakpoint-up(lg) {
        padding: 10px 25px;
      }
      align-items: center;
      justify-content: flex-end;

      &:nth-child(odd) {
        background: $bb-green-medium;
      }

      &:nth-child(even) {
        background: $bb-green-light;
      }

      &:last-child {
        margin-top: 0px;
      }

      & > p {
        color: $bb-green;
      }
    }
  }
}
