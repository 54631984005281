@import 'app/assets/scss/index.scss';

.orders-line-datatable {
  .summary {
    padding: 25px 59px;
    border-radius: 4px;
    width: 100%;
    background-color: $bb-grey-7;
    display: flex;
    flex-direction: row-reverse;

    &-container {
      display: flex;
      flex-direction: column;

      width: 100%;
      @include media-breakpoint-up(md) {
        width: 33%;
      }
    }

    &-line {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;

      p.blue {
        color: $bb-secondary;
      }
    }
  }

  .numeric {
    text-align: right;
  }
}
