@import 'app/assets/scss/index.scss';

@keyframes rmaProductsTableIn {
  from {
    transform-origin: top;
    transform: scaleY(0);
    height: 0 !important;
    max-height: 0 !important;
    overflow: hidden !important;
  }

  to {
    transform-origin: top;
    transform: scaleY(1);
    height: auto !important;
    max-height: auto !important;
    overflow: hidden !important;
  }
}

@keyframes rmaProductsTableContent {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.table-products {
  #products-rma {
    .product-formatter {
      width: 250px;
    }

    .column-description {
      width: 250px;
      min-width: 250px;
    }

    .rma-editable-row .bb-textarea-form-group {
      margin-bottom: 0;
    }

    .expand-cell {
      cursor: pointer;
      svg {
        display: inline-block;
      }
      & svg:first-child {
        margin-right: 12px;
      }
      &-header {
        pointer-events: none;
      }
    }

    .devolutions__alert-actions {
      display: flex;
      flex-wrap: nowrap;
      min-width: 42px;
    }
    tr.grey {
      color: $bb-grey-4 !important;
    }
    tr:has(.row-expand-slide-exit) {
      animation-duration: 400ms;
      animation-name: rmaProductsTableIn;
      animation-delay: 0;
      animation-direction: reverse;
      overflow: hidden !important;
      animation-fill-mode: backwards;
      height: auto !important;

      * {
        animation-duration: 400ms;
        animation-name: rmaProductsTableContent;
        animation-delay: 0;
        animation-direction: reverse;
        animation-fill-mode: backwards;
      }
    }

    tr:has(.row-expand-slide-appear-active) {
      animation-duration: 400ms;
      animation-name: rmaProductsTableIn;
      animation-delay: 0;
      animation-fill-mode: forwards;

      * {
        animation-duration: 400ms;
        animation-name: rmaProductsTableContent;
        animation-delay: 0;
        animation-fill-mode: forwards;
      }
    }
  }

  .bb-separator--dash {
    margin-top: 10px;
  }

  .bb-textarea-form-group {
    margin-bottom: 10px;
    max-width: 500px;
  }

  .chat-message-actions {
    display: flex;
    gap: 7px;
    margin-top: 20px;

    &--comment {
      max-width: 500px;
      display: flex;
      justify-content: flex-end;
      gap: 7px;
    }
  }
}
