@import '../../assets/scss/index.scss';

.bb-card {
  padding: 20px 15px;
  @include media-breakpoint-up(lg) {
    padding: 25px 30px;
  }
  box-shadow: 10px 10px 30px rgba(181, 181, 195, 0.4);
  border-radius: 4px;

  &__preheader {
    margin-bottom: 20px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 25px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      &__title {
        display: flex;
        align-items: center;

        &__icon {
          display: flex;
          padding: 8px;
          border-radius: 6px;
          align-items: center;
          justify-content: center;
          margin-right: 15px;
          width: 32px;
    height: 32px;
          @include media-breakpoint-up(lg) {
            margin-right: 10px;
          }
        }

        & > p {
          display: flex;
          color: $bb-grey-2;
        }
      }

      &__buttons {
        display: flex;
        margin-top: 15px;
        @include media-breakpoint-up(lg) {
          margin-top: 0px;
        }

        & > button {
          margin-right: 10px;

          &:last-child {
            margin-right: 0px;
          }
        }
      }

      &__collapsable {
        margin-left: 5px;
        transform: rotate(-90deg);
        transition: transform 0.5s;
        &.open {
          transform: rotate(90deg);
          transition: transform 0.5s;
        }
      }
    }

    & > hr {
      position: relative;
      left: -15px;
      width: calc(100% + 30px);
      margin: 25px 0px;
      @include media-breakpoint-up(lg) {
        left: -30px;
        width: calc(100% + 60px);
        margin: 30px 0px;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;

      &--spaced {
        margin-top: 20px;
      }
    }
  }
}
