@import 'app/assets/scss/index.scss';

.bb-alert {
  border-radius: 4px;

  &__container {
    display: grid;
    grid-template:
      'header-main header-right'
      'body-main   body-right';
    grid-template-columns: 1fr auto;
    padding: 8px;
    border-radius: 4px;

    @include media-breakpoint-up(lg) {
      padding: 10px;
    }

    &.gap {
      gap: 8px;
    }
  }

  &__header {
    grid-area: header-main;
  }

  &__body {
    grid-area: body-main;
  }

  &__dismiss-btn {
    &--in-header {
      grid-area: header-right;
    }
    &--in-body {
      grid-area: body-right;
    }
  }

  &.fade {
    transition: opacity 0.15s linear;
  }

  &__actions {
    cursor: pointer;
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    & .svg-inline--fa .fa-secondary {
      opacity: 1 !important;
    }
  }

  &__wrapper {
    position: fixed;
    width: 100vw;
    z-index: 99;
    padding: 24px;
    padding-bottom: 30px; // kt_scrolltop button bottom
    display: flex;

    @include media-breakpoint-up(lg) {
      padding-bottom: 40px; // kt_scrolltop button bottom
    }

    @include media-breakpoint-down(sm) {
      padding: 0;
      padding-bottom: 16px; // kt_scrolltop button bottom
    }

    &.fixed-to-bottom {
      bottom: 0;
      left: 0;
    }

    &.fixed-to-top {
      top: 0;
      left: 0;
    }

    &.align-left {
      justify-content: flex-start;
    }

    &.align-center {
      justify-content: center;
    }

    &.align-right {
      justify-content: flex-end;
    }

    & .bb-alert {
      width: 40%;
      min-width: 300px;

      @include media-breakpoint-down(sm) {
        min-width: 90vw;
        margin: auto;
      }

      &.fixed-full-width {
        width: 100%;
      }
    }
  }

  &.bb-alert-success {
    background-color: $bb-green-medium;
    border-color: $bb-green-medium;
    color: $bb-green;
  }

  &.bb-alert-danger {
    background-color: $bb-red-medium;
    border-color: $bb-red-medium;
    color: $bb-red;
  }

  &.bb-alert-warning {
    background-color: $bb-orange-light;
    border-color: $bb-orange-light;
    color: $bb-orange;
  }

  &.bb-alert-info {
    background-color: $bb-secondary-medium;
    border-color: $bb-secondary-medium;
    color: $bb-secondary-dark;
  }
}

body[data-scrolltop='on'] {
  &:has(.bb-alert__wrapper.fixed-to-bottom.align-right) {
    @include media-breakpoint-down(sm) {
      #kt_scrolltop {
        display: none;
      }
    }
  }
  .bb-alert__wrapper.fixed-to-bottom.align-right {
    @include media-breakpoint-up(md) {
      padding-right: 76px; // 36px kt_scrolltop button width + (20px x 2) button margins
    }
    @include media-breakpoint-up(lg) {
      padding-right: 100px; // 24px regular margin + 36px kt_scrolltop button width + (20px x 2) button margins
    }
  }
}
