@import 'app/assets/scss/index.scss';

.bb-notification-panel {
  &--regular-font {
    font-size: 13px !important;
    color: $bb-grey-2 !important;

    @include media-breakpoint-up(lg) {
      font-size: 14px !important;
    }

    &.margin {
      margin: 10px 0;

      @include media-breakpoint-up(lg) {
        margin: 20px 0;
      }
    }
  }
}
