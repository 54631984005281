@import 'app/assets/scss/index.scss';

.shopify-form {
  &__container {
    &-title {
      color: $bb-grey-2;
      margin-top: 13px;
      margin-bottom: 20px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 25px;
      }
    }

    &:first-child .shopify-form__container-title {
      margin-top: 20px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 30px;
      }
    }
  }

  &__input {
    &-wrapper {
      align-items: center;
      margin-bottom: 20px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 25px;
      }

      &.files,
      .selects {
        margin-bottom: 10px !important;
      }

      & #languages,
      & #currency,
      & #country,
      & #catalogue,
      & #theme {
        svg {
          color: $bb-grey-3 !important;
        }
        color: $bb-grey-4 !important;
      }

      &--select {
        margin-bottom: 7px;
      }
    }

    &-label {
      p {
        color: $bb-grey-2;
        font-size: 14px !important;
        margin-bottom: 7px !important;

        @include media-breakpoint-up(lg) {
          margin-bottom: 0 !important;
        }
      }
      &.domain {
        display: flex;
        align-items: center;

        & svg {
          margin-bottom: 7px !important;

          @include media-breakpoint-up(lg) {
            margin-bottom: 0 !important;
          }
        }
      }
    }

    & .bb-form-group {
      margin: 0;
    }

    .input-group {
      .input-group-append,
      .input-group-prepend {
        & .input-group-text {
          background-color: $bb-grey-9 !important;
          color: $bb-grey-4 !important;
          &.disabled {
            background-color: $bb-grey-9 !important;
            color: $bb-grey-4 !important;
          }
        }
      }
    }

    &__files__description__link {
      p {
        font-size: 14px !important;
      }
    }
  }
}
