@import 'app/assets/scss/index.scss';

.bb-payment-methods-credit-cards {
  &__credit-cards-item {
    display: flex;
    align-items: center;
    padding: 20px 15px;
    border: 1px dashed #e5eaee;
    margin: 0 !important;
    margin-bottom: 20px !important;
    &:last-child {
      margin-bottom: 0 !important;
    }
    @include media-breakpoint-up(md) {
      margin-bottom: 0 !important;
    }

    @include media-breakpoint-up(md) {
      padding: 10px 0;
      border: none;
      flex-wrap: nowrap !important;
    }

    &--mobile-name {
      margin-bottom: 15px;
      padding: 0 !important;
      max-width: 50% !important;
      overflow: hidden;

      & > p {
        font-size: 13px;
        color: #464e5f;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &--mobile-notification {
      max-width: 50% !important;
      padding: 0 !important;
      display: flex;
      justify-content: flex-end;

      & > .bb-badge {
        margin: 0;
        white-space: nowrap;
        margin-bottom: 15px;
      }
    }

    &--toggler {
      transition: all 0.2s ease;

      &.active {
        transform: rotate(90deg);
      }
    }

    &--image {
      max-width: 30% !important;
      width: auto !important;
      margin-right: 15px;
      padding: 0 !important;
      @include media-breakpoint-up(md) {
        margin: 0;
        display: flex;
        align-items: center;
      }
      & img {
        width: 100%;
        max-width: 80px;

        @include media-breakpoint-up(md) {
          max-width: 40px;
        }
      }
    }

    &--toggler {
      margin-right: 15px;
      cursor: pointer;
    }

    &--info {
      max-width: calc(70% - 15px) !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 0 !important;
      & > p {
        display: block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 0;

        @include media-breakpoint-up(xxl) {
          margin-left: -20px;
        }
      }

      &-container {
        display: flex;
        flex-direction: column;
      }

      &-title {
        color: #464e5f;
        font-size: 13px;
        font-weight: 500;

        @include media-breakpoint-up(md) {
          font-size: 14px;
        }
      }

      &-subtitle {
        color: #b5b5c3;
        margin-top: 4px !important;
        font-size: 13px;

        @include media-breakpoint-up(md) {
          font-size: 14px;
        }
      }
    }

    &--interface {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      padding: 0 !important;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
      }

      &.right-align {
        justify-content: flex-end;
      }
    }

    &--notification {
      margin: 15px 0;
      white-space: nowrap;

      @include media-breakpoint-up(md) {
        margin: 0;
      }
    }

    &--actions {
      display: flex;
      flex-wrap: wrap;

      @include media-breakpoint-up(md) {
        justify-content: flex-end;
        flex-wrap: nowrap;
        align-items: center;
      }

      & > button {
        padding: 8px 15px !important;
        height: auto;
        margin-top: 0;
        margin-right: 15px;
        margin-top: 15px;
        white-space: nowrap;

        @include media-breakpoint-up(md) {
          margin: 0;
          margin-left: 7px;
          height: 28px;
          width: 28px;
        }

        & > p {
          font-size: 14px !important;
          line-height: normal !important;
        }
      }
    }

    &--collapse {
      margin-top: 25px;
      &-element {
        margin-bottom: 7px;
        padding-left: 20px;
        @include media-breakpoint-up(md) {
          margin: 0 0 7px 0 !important;
        }
      }

      &-title {
        font-size: 14px;
        color: #7e8299;
        padding: 0 !important;
      }

      &-value {
        font-size: 14px;
        color: #464e5f;
        font-weight: 500;
        padding: 0 !important;
      }

      &:last-child {
        margin-bottom: -25px;
      }
    }
  }
}
