@import 'app/assets/scss/utils/variables.scss';

.bb-notification-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;

  &.text {
    padding: 3px;
    width: auto !important;
    min-width: 18px !important;
    height: auto !important;
  }

  &__square {
    max-width: max-content;
    padding: 3px 7px;
    border-radius: 4px;

    &--red {
      color: $bb-red;
      background-color: $bb-red-medium;
    }

    &--green {
      color: $bb-green;
      background-color: $bb-green-medium;
    }

    &--yellow {
      color: $bb-primary;
      background-color: $bb-primary-light;
    }

    &--blue {
      color: $bb-secondary;
      background-color: $bb-secondary-medium;
    }

    &--purple {
      color: $bb-purple;
      background-color: $bb-purple-medium;
    }

    &--grey {
      color: $bb-grey-3;
      background-color: $bb-grey-7;
    }
  }

  &__circle {
    max-width: max-content;
    padding: 2px 5px;
    border-radius: 10px;

    &--red {
      color: $bb-white;
      background-color: $bb-red;
    }

    &--green {
      color: $bb-white;
      background-color: $bb-green;
    }

    &--yellow {
      color: $bb-white;
      background-color: $bb-primary;
    }

    &--blue {
      color: $bb-white;
      background-color: $bb-secondary;
    }

    &--purple {
      color: $bb-white;
      background-color: $bb-purple;
    }

    &--grey {
      color: $bb-white;
      background-color: $bb-grey-4;
    }
  }

  &__default {
    width: 10px;
    height: 10px;
    border-radius: 10px;

    &--red {
      background-color: $bb-red;
    }

    &--green {
      background-color: $bb-green;
    }

    &--yellow {
      background-color: $bb-primary;
    }

    &--blue {
      background-color: $bb-secondary;
    }

    &--purple {
      background-color: $bb-purple;
    }

    &--grey {
      background-color: $bb-grey-4;
    }
  }

  > div {
    font-family: Roboto;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    font-style: normal;
    color: $bb-white;
  }
}
