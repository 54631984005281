@import 'app/assets/scss/index.scss';

.devolutions {
  &-card {
    margin: 10px 0;
    @include media-breakpoint-up(md) {
      margin: 0 15px;
      flex: 0 0 49% !important;
      max-width: 49% !important;
    }
  }
  &-table {
    padding: 0 !important;
  }
  &-faqs-container {
    margin-top: 15px;
    @include media-breakpoint-up(md) {
      margin-top: 25px;
    }
    .bb-card__container__header {
      flex-wrap: nowrap;
      @include media-breakpoint-up(md) {
        flex-wrap: wrap;
      }
    }
  }
  &-add {
    .bb-card__container__header {
      row-gap: 18px !important;
    }
  }
  &-faqs {
    &-subtitle {
      margin-bottom: 25px !important;
      margin-top: 20px !important;
      @include media-breakpoint-up(md) {
        margin-top: 20px !important;
      }
    }
    &-list {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-bottom: 0;
      padding-inline-start: 20px;
      @include media-breakpoint-up(md) {
        padding-inline-start: 40px;
      }
    }
    &-title {
      margin-top: 15px !important;
      @include media-breakpoint-up(md) {
        margin-top: 25px !important;
      }
    }
    &-status {
      display: flex;
      flex-direction: column;
      gap: 15px;
      &-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @include media-breakpoint-up(md) {
          flex-direction: row;
          align-items: center;
        }
        p {
          margin-top: 10px;
          @include media-breakpoint-up(md) {
            display: list-item;
            list-style-type: disc;
            margin-left: 30px;
            margin-top: 0;
          }
        }
        .bb-badge div {
          width: max-content;
        }
      }
    }
    .no-bold {
      font-weight: normal;
    }
  }
}
