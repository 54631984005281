@import 'app/assets/scss/index.scss';

.dropshipping-shop-banner {
  text-align: center;
  color: $bb-white !important;
  background-color: #b896fd !important;
  padding: 20px 15px 58px 15px !important;
  background-image: url('../../assets/png/dropshipping-shop-card/mobile-background-left-top-inner.png'),
    url('../../assets/png/dropshipping-shop-card/mobile-background-left-top-outer.png'),
    url('../../assets/png/dropshipping-shop-card/mobile-background-right-bottom.png');
  background-position: 0px 30px, 0px 0px, right bottom;
  background-size: 39.38px, 87.81px, 301.44px;
  background-repeat: no-repeat;

  @include media-breakpoint-up(md) {
    width: 100%;
    display: flex;
    align-items: center;
    background-image: url('../../assets/png/dropshipping-shop-card/background-bottom-left.png'),
      url('../../assets/png/dropshipping-shop-card/background-center-right.png');
    background-position: left bottom, right center;
    background-size: 233px, 380px;
    background-repeat: no-repeat;
    padding: 14px 50px 19px 28px !important;
  }

  &-logo {
    height: 100px;
    min-width: 105.35px;
    @include media-breakpoint-up(md) {
      height: 170px;
      min-width: 170px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 50px;
    }
    & svg {
      height: 55.43px;
      width: 70.91px;
      @include media-breakpoint-up(md) {
        height: 114px;
        width: 114px;
      }
    }
  }

  &-title {
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
    }

    h4 {
      margin-bottom: 15px;
      max-width: 420px;

      @include media-breakpoint-up(md) {
        margin-bottom: 10px;
      }
    }
  }

  &-description p {
    @include media-breakpoint-up(md) {
      font-size: 16px !important;
      text-align: left;
    }
  }

  &-content {
    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
    }
  }

  &-button {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    &--fullwidth {
      width: 100%;
    }
    @include media-breakpoint-up(md) {
      margin-top: 25px;
    }
  }

  &-ecomerce-logos {
    margin-top: 25px;

    @include media-breakpoint-up(md) {
      margin-top: 0;
      height: 173px;
      width: 133px !important;
      display: flex;
      position: relative;
    }

    & .ecommerce-logo {
      max-height: 40px;
      max-width: 40px;
      @include media-breakpoint-up(md) {
        max-height: 80px;
        max-width: 80px;
      }

      &.shopify-default {
        margin-right: 15px;
        @include media-breakpoint-up(md) {
          position: absolute;
          top: 0;
          right: 0;
          margin-right: 0;
        }
      }
      &.prestashop-default {
        @include media-breakpoint-up(md) {
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }
  }

  &-title {
    margin-top: 0 !important;

    &.big {
      margin-top: 25px;
    }
  }
}
