@import 'app/assets/scss/index.scss';

.total-refund {
  display: inline-flex;
  width: 100%;

  .percentage {
    color: $bb-grey-4;
  }

  .tooltip-container {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    margin-left: 10px;
    svg {
      height: 16px;
    }
  }
}
