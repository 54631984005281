.hide{
    display: none !important;
}

.show{
    display: block !important;
}

.v-hidden{
    visibility: hidden !important;
}

.v-visible{
    visibility: visible !important;
}
