@import '../../assets/scss/index.scss';

.dropshipping-shop-card {
  border-radius: 4px !important;
  box-shadow: 10px 10px 30px rgba(181, 181, 195, 0.4) !important;

  .center {
    display: flex;
    justify-content: center;
  }

  &__banner {
    text-align: center;
    color: $bb-white !important;
    background-color: #b896fd !important;
    padding: 30px 15px 25px 15px !important;
    background-image: url('../../assets/png/dropshipping-shop-card/mobile-background-left-top-inner.png'),
      url('../../assets/png/dropshipping-shop-card/mobile-background-left-top-outer.png'),
      url('../../assets/png/dropshipping-shop-card/background-bottom-left.png');
    background-position: 0px 30px, 0px 0px, right bottom;
    background-size: 39.38px, 87.81px, 301.44px;
    background-repeat: no-repeat;
    &.no-shops {
      padding: 50px 15px 25px 15px !important;
    }

    &-logo {
      height: 100px;
      min-width: 105.35px;
      @include media-breakpoint-up(md) {
        height: 170px;
        min-width: 170px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 50px;
      }
      & svg {
        height: 55.43px;
        width: 70.91px;
        @include media-breakpoint-up(md) {
          height: 114px;
          width: 114px;
        }
      }
    }

    &-title {
      display: flex;
      flex-direction: column;
      margin-top: 0 !important;
      &.big {
        margin-top: 25px;
      }
      h4 {
        margin-bottom: 15px;
        margin-top: 10px;
        @include media-breakpoint-up(md) {
          margin-bottom: 15px;
          margin-top: 20px;
        }
      }
    }

    &-description p {
      text-align: center;
    }

    &-content {
      @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }

    &-button {
      margin-top: 20px;
      @include media-breakpoint-up(md) {
        margin-top: 25px;
      }
    }

    &-ecomerce-logos {
      margin-top: 30px;

      @include media-breakpoint-up(md) {
        margin-top: 25px;
        display: flex;
      }

      & .ecommerce-logo {
        max-height: 40px;
        max-width: 40px;
        @include media-breakpoint-up(md) {
          max-height: 80px;
          max-width: 80px;
        }
      }
    }
  }

  &__shops {
    &.no-shops {
      display: none;
    }
  }
}
