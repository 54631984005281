.bb-carousel {
  & .control-dots {
    & .dot {
      opacity: 1;
      background: #fffbee;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      box-shadow: none;

      &.selected {
        background: #fab446;
        box-shadow: inset 0px 0px 0px 2px #fff;
      }
    }
  }
}
