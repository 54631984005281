@import 'app/assets/scss/index.scss';

.bb-billing-page {
  .input-billing.row {
    margin-bottom: 25px;

    .col-md-3 {
      display: flex;
      align-items: center !important;
    }
    .col-md-7 {
      .bb-form-group {
        margin-bottom: 0;
      }
    }
  }

  .bb-separator--dash {
    margin-bottom: 25px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 30px;
      margin-top: 45px;
    }
  }

  .bb-billing-page__sub-title {
    margin-bottom: 20px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 25px;
    }
  }

  &--loading {
    height: 100%;

    & .bb-formcard {
      height: 100%;

      &__container {
        height: auto;
      }
    }
  }
}
