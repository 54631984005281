.bb-group-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  border: none;
  border-radius: 2px;

  &-size {
    &--default {
      height: 48px;
      padding: 12px;
    }
    &--small {
      height: 40px;
      padding: 8px;
    }
  }

  &__spinner {
    position: absolute;
  }

  &__content {
    font-family: Roboto;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &--loading {
    pointer-events: none;
    position: relative;
    .bb-group-btn__content {
      visibility: hidden;
    }
  }

  &:enabled {
    cursor: pointer;
  }

  &:focus-visible {
    outline: 2px solid #0082e5;
  }

  & svg {
    fill: #ffffff;
  }

  &--flat {
    color: #ffffff;

    &[disabled] {
      background-color: #cccfd3;
      color: #fff;

      & svg {
        fill: #fff;
      }
    }

    &--primary {
      background-color: #f2b801;

      @media (hover: hover) {
        &:hover:enabled {
          background-color: #e1ab00;
        }
      }

      &:active:enabled {
        background-color: #af8500;
      }
    }
    &--secondary {
      background-color: #50565b;

      @media (hover: hover) {
        &:hover:enabled {
          background-color: #404549;
        }
      }

      &:active:enabled {
        background-color: #303437;
      }
    }
    &--tertiary {
      color: #0090ff;
      background-color: #f3f6f9;

      & svg {
        fill: #0090ff;
      }

      @media (hover: hover) {
        &:hover:enabled {
          background-color: #dceaf6;
        }
      }

      &:active:enabled {
        background-color: #c4e1f7;
      }
    }
    &--destructive {
      background-color: #cf0e08;

      @media (hover: hover) {
        &:hover:enabled {
          background-color: #a60b06;
        }
      }

      &:active:enabled {
        background-color: #7c0805;
      }
    }
  }
  &--outline {
    background-color: transparent;

    &[disabled] {
      color: #cccfd3;
      border: 1.5px solid #cccfd3;

      & svg {
        fill: #cccfd3;
      }
    }

    &--primary {
      color: #f2b801;
      border: 1.5px solid #f2b801;

      & svg {
        fill: #f2b801;
      }

      @media (hover: hover) {
        &:hover:enabled {
          color: #c89800;
          border: 1.5px solid #c89800;
        }
      }

      &:active:enabled {
        border: 1.5px solid #af8500;
        color: #fff;
        background-color: #af8500;
      }
    }
    &--secondary {
      color: #50565b;
      border: 1.5px solid #50565b;

      & svg {
        fill: #61666b;
      }

      @media (hover: hover) {
        &:hover:enabled {
          color: #383c40;
          border: 1.5px solid #383c40;
        }
      }

      &:active:enabled {
        border: 1.5px solid #303437;
        color: #fff;
        background-color: #303437;
      }
    }
    &--tertiary {
      color: #0090ff;
      border: 1.5px solid #0090ff;

      & svg {
        fill: #0090ff;
      }

      @media (hover: hover) {
        &:hover:enabled {
          color: #0075ce;
          border: 1.5px solid #0075ce;
        }
      }

      &:active:enabled {
        border: 1.5px solid #c4e1f7;
        background-color: #c4e1f7;
      }
    }
    &--destructive {
      color: #cf0e08;
      border: 1.5px solid #cf0e08;

      & svg {
        fill: #cf0e08;
      }

      @media (hover: hover) {
        &:hover:enabled {
          color: #a60b06;
          border: 1.5px solid #a60b06;
        }
      }

      &:active:enabled {
        border: 1.5px solid #7c0805;
        color: #fff;
        background-color: #7c0805;
      }
    }
  }
  &--ghost {
    background-color: transparent;

    &[disabled] {
      color: #cccfd3;

      & svg {
        fill: #cccfd3;
      }
    }

    &--primary {
      color: #f2b801;

      & svg {
        fill: #f2b801;
      }

      @media (hover: hover) {
        &:hover:enabled {
          color: #c89800;
        }
      }

      &:active:enabled {
        color: #fff;
        background-color: #af8500;
      }
    }
    &--secondary {
      color: #50565b;

      & svg {
        fill: #61666b;
      }

      @media (hover: hover) {
        &:hover:enabled {
          color: #383c40;
        }
      }

      &:active:enabled {
        color: #fff;
        background-color: #303437;
      }
    }
    &--tertiary {
      color: #0090ff;

      & svg {
        fill: #0090ff;
      }

      @media (hover: hover) {
        &:hover:enabled {
          color: #0075ce;
        }
      }

      &:active:enabled {
        background-color: #c4e1f7;
      }
    }
    &--destructive {
      color: #cf0e08;

      & svg {
        fill: #cf0e08;
      }

      @media (hover: hover) {
        &:hover:enabled {
          color: #a60b06;
        }
      }

      &:active:enabled {
        color: #fff;
        background-color: #7c0805;
      }
    }
  }

  &--link {
    border: 0px;
    color: #0090ff;
    background-color: transparent;
    width: auto;
    height: auto;
    padding: 0px;
  }
}
