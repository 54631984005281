@import 'app/assets/scss/index.scss';

.shopify-form {
  &__input {
    &__files {
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 25px;
      }

      &__container {
        display: flex;
        flex-direction: column;

        &__row {
          display: flex;
          position: relative;
          flex-direction: column;

          @include media-breakpoint-up(lg) {
            flex-direction: row;
            align-items: center;
          }

          &__logo {
            width: 75px;
            height: 75px;
            background: $bb-grey-9;
            border-radius: 4px;
            min-width: 75px !important;
            @include media-breakpoint-up(lg) {
              margin-right: 35px;
            }
          }

          & > span {
            position: absolute;
            top: 0px;
            left: 70px;
            width: 32px;
            height: 32px;
            background: $bb-white;
            box-shadow: 0px 3px 10px rgba(181, 181, 195, 0.3);
            border-radius: 50%;
            cursor: pointer;

            & > svg {
              position: relative;
              top: 6px;
              left: 8px;
            }
          }

          & > img {
            width: 75px;
            height: 75px;
            border-radius: 4px;
          }

          &__title {
            margin-top: 10px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            @include media-breakpoint-up(lg) {
              width: 70%;
              padding: 10px;
              margin-top: 0px;
              flex-direction: row;
              align-items: center;
            }

            & > p {
              color: $bb-grey-4;
            }

            &.full-size {
              width: 100%;
              flex-direction: column;
              align-items: flex-start;

              @include media-breakpoint-up(lg) {
                flex-direction: row;
              }
              & > p {
                @include media-breakpoint-up(lg) {
                  width: 50%;
                }
              }
              & > button {
                margin-top: 15px;

                @include media-breakpoint-up(lg) {
                  margin-top: 0;
                  margin-left: 18px;
                }
              }
            }
          }
        }
      }

      &__label p {
        color: $bb-grey-2;
        font-size: 14px !important;
        margin-bottom: 8px !important;

        @include media-breakpoint-up(lg) {
          margin-bottom: 30 !important;
        }
      }

      &__description {
        margin-bottom: 20px !important;
        margin-top: 5px;
        @include media-breakpoint-up(lg) {
          margin-bottom: 28px !important;
          margin-top: 0;
        }

        &.full-size {
          margin-top: 7px;
          margin-bottom: 0px !important;

          @include media-breakpoint-up(lg) {
            margin-top: 0px;
            margin-bottom: 28px !important;
          }
        }

        & > p {
          color: $bb-grey-3;
        }
        &.last {
          margin-bottom: 28px !important;
        }

        &__link {
          color: $bb-secondary;
          text-decoration: underline;
          margin-top: -5px;
          margin-bottom: 20px !important;
          @include media-breakpoint-up(lg) {
            margin-top: -15px;
            margin-bottom: 30px;
          }
        }
      }

      &__input {
        & > div {
          margin-bottom: 0px;
        }
      }

      &:nth-last-child(2) {
        margin-bottom: 0px !important;
      }
    }
  }
}
