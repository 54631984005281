@import 'app/assets/scss/index.scss';

.bb-popup-repeat-order {
  &__header {
    & > p {
      color: $bb-grey-2;
    }
  }

  &__body {
    & > p {
      color: $bb-grey-2;
    }
  }

  &__footer {
    display: flex;

    & > button {
      margin-right: 10px;
      @include media-breakpoint-up(lg) {
        margin-right: 25px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

