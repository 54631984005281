.notification-dialog {
  padding: 24px;
  display: flex;
  gap: 24px;
  flex-direction: column;
  position: relative;
  &-close {
    position: absolute;
    right: 40px;
    top: 40px;
    color: #697077;
    cursor: pointer;
    --fa-primary-color: #607077;
    --fa-secondary-color: #f3f6f9;
    --fa-secondary-opacity: 1;
  }
  &-info {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &-title {
    text-align: center;
  }
  &-image {
    width: -webkit-fill-available;
  }
  &-separator {
    border-top: 1px solid #e4e5e5;
  }
}
