@import '../../../../../assets/scss/index.scss';

.bb-wholesaler-pack-modal {
  svg {
    cursor: pointer;
  }

  &__label {
    margin: 15px 0 10px 0 !important;
  }

  &__span {
    color: $bb-red !important;
    margin-right: 5px;
  }

  textarea {
    width: 100%;
  }

  .bb-textarea-form-group {
    margin: 0;
  }

  @include media-breakpoint-up(md) {
    .modal-header,
    .modal-body,
    .modal-footer {
      padding: 16px 15px !important;
    }

    button {
      margin-left: 15px;
    }
  }

  @include media-breakpoint-down(md) {
    .modal-header,
    .modal-body,
    .modal-footer {
      padding: 15px;
    }

    button {
      margin-left: 15px;
    }
  }
}
