@import 'app/assets/scss/utils/variables.scss';

.date-formatter {
  &__hour {
    color: $bb-grey-3;

    &.warning {
        color: $bb-red-medium;
    }
  }
}
