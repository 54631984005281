@import 'app/assets/scss/index.scss';

.social-selling-resources {
  .filters-form {
    padding-bottom: 0;
    @include media-breakpoint-up(lg) {
      padding-bottom: 15px;
    }
  }
}

.social-selling-resources__card-info {
  margin-top: 15px;
  margin-bottom: 15px;

  @include media-breakpoint-up(lg) {
    margin-top: 25px;
  }

  .text-container {
    padding-top: 20px;
    color: $bb-grey-2;
    p {
      margin-bottom: 20px !important;
    }
  }
}
