@import 'app/assets/scss/index.scss';

.shop-shipping-process {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px 30px;
  box-shadow: 10px 10px 30px rgba(181, 181, 195, 0.4);
  background-color: $bb-white;
  border-radius: 4px;

  &__title {
    color: $bb-grey-2;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-bottom: 25px;
    }
  }
}
