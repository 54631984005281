@import '../../assets/scss/index.scss';

.connector-list-card {
  margin-bottom: 15px;

  &_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background: $bb-white;
    box-shadow: 0px 3px 10px rgba(216, 216, 216, 0.6);
    border-radius: 4px;
    height: 130px;
    min-height: 130px;
    padding: 15px;
    padding-top: 0;

    &.active {
      background: $bb-green-light;
      border: 2px solid $bb-green-medium;
    }

    &__info {
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;
      height: 100%;
    }

    &__flag {
      order: 1;
      display: flex;
      justify-content: center;
      width: 100%;
      @include media-breakpoint-up(lg) {
        order: 2;
        width: auto;
      }

      &--icon {
        margin: 5px;
        border-radius: 4px;
        height: 17px;
        width: 17px;
      }
    }

    &__image {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      order: 2;
      height: auto;
      width: 100%;
      max-height: 40px;

      @include media-breakpoint-up(lg) {
        height: 40px;
      }

      > img {
        max-height: 40px;
        max-width: 100%;
        min-block-size: stretch;

      }

      @include media-breakpoint-up(lg) {
        order: 1;
        flex-direction: row;
      }
    }

    &__name {
      display: flex;
      align-items: center;
      order: 2;
      padding: 5px;

      @include media-breakpoint-up(lg) {
        order: 1;
      }
    }

    &__tooltip {
      order: 3;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__date {
      width: 100%;
      color: $bb-green;
      display: flex;
      justify-content: space-between;
      text-transform: capitalize;
    }

    &__button {
      margin-bottom: 7px;
    }
  }
}
