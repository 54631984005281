@import 'app/assets/scss/index.scss';

.filters-form {
  padding: 20px 0 !important;

  @include media-breakpoint-up(md) {
    padding: 25px 0 !important;
  }

  &__search {
    display: block;
    @include media-breakpoint-up(md) {
      display: flex;
    }
    .bb-search__container {
      margin-bottom: 20px;
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }

    .bb-search__container {
      width: 100%;
      @include media-breakpoint-up(md) {
        width: 80%;
        margin-right: 16px;
      }
    }
  }

  &__selects {
    margin-top: 20px;
    display: block !important;
    @include media-breakpoint-up(md) {
      display: flex !important;
      margin-top: 25px;
    }
  }
}
