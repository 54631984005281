@import '../../../assets/scss/index.scss';

@mixin bb-action--normal {
  padding-bottom: 25px;

  .bb-pack-info-action__button {
    min-height: 120px;
  }

  .bb-pack-info-action--multiple-lines {
    flex-direction: column;

    .bb-pack-info-action__text {
      margin-top: 5px;
    }
  }

  .bb-pack-info-action__svg-icon {
    height: 40px;
    width: 40px;
  }

  .bb-pack-info-action--one-line {
    padding-left: 35px;

    .bb-pack-info-action__text {
      margin: 0 15px;
    }
  }
}

@mixin bb-action--small {
  padding-bottom: 15px;

  .bb-pack-info-action__button {
    min-height: 70px;
  }

  .bb-pack-info-action__custom-svg-icon svg {
    height: 35px;
    width: 35px;
  }

  .bb-pack-info-action__text {
    margin-left: 10px;
  }
}

.bb-pack-info-action {
  height: 100%;

  .fa-duotone-group {
    --fa-secondary-opacity: 1 !important;
    --fa-primary-opacity: 0.3 !important;
  }

  &__custom-svg-icon {
    path {
      fill: $bb-secondary;
    }
  }

  &__button {
    background-color: $bb-grey-9;
    color: $bb-secondary;
    border-radius: 4px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 15px;

    &:hover {
      background-color: $bb-grey-6;
    }

    &--alt {
      background-color: $bb-secondary;
      color: $bb-white !important;

      &:hover {
        color: $bb-white !important;
        background-color: $bb-secondary-dark !important;
      }
    }
  }

  &--normal {
    @include media-breakpoint-up(lg) {
      @include bb-action--normal;
    }

    @include media-breakpoint-down(lg) {
      @include bb-action--small;
    }
  }

  &--small {
    @include bb-action--small;
  }
}
