@import 'app/assets/scss/index.scss';

.bb-change-password-page {
  .bb-formcard__body {
    padding-bottom: 0 !important;
  }

  &__form-row {
    .bb-form-group.form-group.fv-plugins-icon-container {
      margin-bottom: 25px;
    }
    .old-password-input .bb-form-group .input-group input.is-valid {
      background-image: none !important;
    }
  }

  &__label {
    display: flex;
    align-items: center;
  }

  &__mobile-extra {
    margin-bottom: 20px !important;
  }

  @include media-breakpoint-up(lg) {
    &__label {
      min-height: 38px;
    }

    &__mobile-extra {
      display: none;
    }
  }

  @include media-breakpoint-down(lg) {
    &__label {
      margin-bottom: 7px !important;
    }
  }
}
