.shopify-form {
  &__header {
    margin-top: 20px;
    margin-bottom: 15px;
    margin: 0;
  }
}
.bb-topbar {
  .dropdown {
    display: flex !important;
  }
  .user-profile-dropdown {
    .topbar-item {
      display: block !important;
    }
    .bb-btn-item {
      height: 67px !important;
    }
  }
}
