@import 'app/assets/scss/index.scss';

.wishlist {
  .button-delete {
    color: $bb-red !important;
    border: 1px solid $bb-red !important;
    background-color: transparent !important;

    &:hover {
      color: $bb-white !important;
      background-color: $bb-red !important;
      svg {
        color: $bb-white !important;
        --color: $bb-white !important;
      }
    }
  }
  &__datatable {
    margin-bottom: 15px;

    @include media-breakpoint-up(md) {
      margin-bottom: 25px;
    }

    &-container {
      &__buttons-actions {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include media-breakpoint-up(md) {
          justify-content: space-between;
          flex-direction: row;
        }

        > div {
          display: flex;
          gap: 16px;
          flex-direction: column;

          @include media-breakpoint-up(md) {
            justify-content: space-between;
            flex-direction: row;
          }
        }
      }
    }

    &-buttons-header {
      display: flex;
      button:first-child {
        margin-right: 5px;
      }

      .bb-btn__primary {
        color: $bb-white !important;
      }
    }

    &__no-results-found {
      display: flex;
      justify-content: center;
      &-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: 16px;
        @include media-breakpoint-up(md) {
          gap: 24px;
        }

        >p {
          color: #697077;
        }

        >button {
          max-width: fit-content;
        }
      }
    }
  }

  &__howto {
    .bb-card__container__header {
      cursor: pointer;
      flex-wrap: initial !important;
    }
    &-stock-tracking {
      margin-top: 25px;
      .faq__image-container {
        padding: 16px 0;
        display: flex;
        gap: 16px;
        flex-direction: column;
        @include media-breakpoint-up(md) {
          flex-direction: row;
          justify-content: space-evenly;
        }
        img {
          max-width: 100%;
          @include media-breakpoint-up(md) {
            max-width: 40%;
          }
        }
      }
    }
  }
}
