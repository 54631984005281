@import 'app/assets/scss/index.scss';

.icons-list {
  &__items {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
  }

  &__title {
    margin-bottom: 20px !important;
    color: $bb-grey-2;

    @include media-breakpoint-up(md) {
      font-size: 14px !important;
    }
  }

  &__item {
    display: flex;
    margin-bottom: 10px;

    &-icon {
      height: 34px;
      width: 34px;
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-text {
      display: flex;
      align-items: center;
      justify-content: center;

      > p {
        font-size: 13px !important;
        color: $bb-grey-3;

        @include media-breakpoint-up(md) {
          font-size: 14px !important;
        }
      }
    }
  }
}
