@import '../../assets/scss/index.scss';

.pagination {
  display: flex;
  flex-direction: column;

  &__container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 15px;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }
  }

  .pagination-links {
    margin-top: 15px;
  }
}
