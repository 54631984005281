@import 'app/assets/scss/index.scss';

.datatable-filters {
  &-header {
    border-bottom: 0 !important;
    padding: 15px !important;
    @include media-breakpoint-up(lg) {
      padding: 25px 30px !important;
    }
  }

  &-body {
    padding-top: 0 !important;
    @include media-breakpoint-up(lg) {
      padding: 0 30px !important;
    }

    &__row {
      &-element {
        margin-bottom: 15px !important;
        @include media-breakpoint-up(lg) {
          margin-bottom: 30px !important;
        }

        &-button {
          @include media-breakpoint-up(lg) {
            justify-content: center;
            display: flex;
          }
        }
      }

      &-divider {
        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
    }
  }
}
