@import '../../assets/scss/index.scss';

.bb-tab-bar {
  margin-bottom: 20px;
  border-bottom: 1px solid $bb-grey-6 !important;
  @include media-breakpoint-up(lg) {
    margin-bottom: 25px;
  }
  .MuiTabs-scrollable {
    .MuiTab-textColorInherit{
      opacity: 1 !important;
    }
    
    .MuiTabs-indicator {
      background-color: $bb-secondary;
      margin-bottom: -2px;
      height: 4px;
    }

    .MuiTab-root {
      padding: 20px 7px;
      font-family: Roboto !important;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
      text-transform: inherit !important;
      color: $bb-grey-2 !important;

      &.Mui-selected {
        font-weight: 700 !important;
        color: $bb-secondary !important;
      }

      @include media-breakpoint-up(lg) {
        padding: 25px 15px;
        font-size: 14px !important;
        line-height: 16px !important;

        &.Mui-selected {
          font-weight: 700 !important;
        }
      }
    }
  }
}
