@import 'app/assets/scss/index.scss';

.products-manuals {
  margin-top: 15px;
  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }

  .bb-card__container__header__element {
    margin: 20px 0;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  .languages-reference {
    margin-right: 20px;
  }

  .languages-description {
    p {
      margin-left: 15px;
    }
    img {
      border-radius: 5px;
      width: 40px !important;
    }
  }

  .table-languages {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center !important;
    &__container-image {
      width: 17px;
      height: 17px;
      svg {
        border-radius: 5px;
      }
    }
    p.link.small {
      color: $bb-secondary;
      white-space: nowrap;
      text-decoration: underline;
    }
  }
}
