@import 'app/assets/scss/index.scss';

.pack-container-block {
  padding: 16px 24px 14px 24px;
  background-repeat: no-repeat;
  background-size: 66px;
  border-radius: 4px 4px 0px 0px;
  box-shadow: 0px 2px 6px 2px rgba(126, 130, 153, 0.15);
  background-position-x: 100%;
  background-position-y: 2px;
  width: 208px;
  height: 68px;

  &.ecommerce {
    color: $bb-orange;
    background-color: $bb-orange-light;
    background-image: url('../../assets/svg/cube_orange.svg');
  }

  &.marketplaces {
    color: $bb-green;
    background-color: $bb-green-light;
    background-image: url('../../assets/svg/cube_green.svg');
  }

  &__name {
    p {
      font-size: 16px !important;
    }
  }
}
