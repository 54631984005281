@import 'app/assets/scss/index.scss';

.impersonate-alert {
  height: 36px;
  background-color: $bb-secondary;
  color: $bb-white;
  width: 100%;
  max-width: -webkit-fill-available;
  padding: 0 10px;
  justify-content: space-between;
  position: fixed;
  z-index: 36;
  display: flex;
  align-items: center;
  font-weight: bold !important;

  @include media-breakpoint-up(lg) {
    margin-top: -54px;
  }

  .bb-link {
    color: $bb-white !important;
  }
}

.impersonate.content.d-flex.flex-column.flex-column-fluid {
  margin-top: 36px !important;
}

.impersonate.subheader.py-2.py-lg-4.subheader-solid {
  top: 101px;
}
