@import 'app/assets/scss/index.scss';

.modal-body.data-table-column-order {
  padding: 0 !important;
  .data-table-column-order {
    display: flex;
    flex-direction: column;
    background: $bb-white;
    box-shadow: 10px 10px 30px rgba(181, 181, 195, 0.4);
    border-radius: 4px;
    user-select: none;
    padding: 15px;
    @include media-breakpoint-up(lg) {
      padding: 25px 30px;
    }

    &__container {
      .MuiFormControl-root {
        width: 100%;
        .MuiCheckbox-colorSecondary.Mui-checked {
          color: $bb-secondary;
        }
        .draggable {
          display: flex !important;
          width: auto;
          justify-content: space-between !important;
          align-items: center;
          p {
            color: $bb-grey-3;
          }

          > svg {
            display: none;
          }

          &:hover {
            > svg {
              display: block;
            }
          }
        }
      }
    }
  }
}
