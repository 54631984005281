@import 'app/assets/scss/index.scss';

.bb-profile-card {
  display: flex;
  flex-direction: column;
  background: $bb-white;
  box-shadow: 10px 10px 30px rgba(181, 181, 195, 0.4);
  border-radius: 4px;
  padding: 15px;
  @include media-breakpoint-up(lg) {
    padding: 25px 30px;
  }

  &__header {
    display: flex;
    width: 100%;
    height: 100px;

    &__logo {
      display: flex;
      width: 100px;
      height: 100px;
      background: $bb-grey-9;
      border-radius: 4px;

      & > span {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        font-family: 'Purple Purse';
        font-style: normal;
        font-weight: 400;
        font-size: 42px;
        line-height: 20px;
        color: $bb-secondary;
        background-color: $bb-secondary-light;
        h5 {
          font-size: 42px !important;
          margin-bottom: 0 !important;
        }
      }
    }

    &__info {
      display: flex;
      width: 50%;
      flex-direction: column;
      margin: 10px 0px 10px 30px;

      &__text {
        margin: 5px 0px;
        color: $bb-grey-3;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:last-child {
          margin: 0px;
          margin-top: 4px;
        }
      }
    }
  }

  &__subheader {
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    margin: 25px 0px;
    justify-content: space-between;
    &__title {
      color: $bb-grey-2;
    }

    &__email {
      align-items: center;
      justify-content: flex-end;
      color: $bb-grey-4;
      text-align: right;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 5px;
    }
  }

  &__routes {
    display: flex;
    flex-direction: column;

    &__card {
      display: flex;
      width: 100%;
      height: 48px;
      align-items: center;
      justify-content: flex-start;
      padding: 10px 20px;
      border-radius: 6px;
      margin-bottom: 7px;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0px;
      }

      & > svg {
        path {
          fill: $bb-grey-3;
        }
      }

      & > p {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: flex-start;
        color: $bb-grey-3;
        margin: 0px 0px 0px 10px;
        text-overflow: ellipsis;
      }

      &:hover {
        & > svg {
          path {
            fill: $bb-secondary;
          }
        }
        & > p {
          color: $bb-secondary;
        }
        background: $bb-grey-9;
        transition: 0.3s;
      }

      &--active {
        & > svg {
          path {
            fill: $bb-secondary;
          }
        }
        & > p {
          color: $bb-secondary;
        }
        background: $bb-grey-9;
      }
    }
  }

  &__tag {
    display: flex;
    max-width: max-content;
    border-radius: 4px;
    color: $bb-white;
    cursor: pointer;

    &--regular {
      padding: 5px 7px;
    }
  }
}
