@import 'app/assets/scss/utils/variables.scss';

.bb-select {
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  &.error {
    div[class$='-ValueContainer'] {
      border-color: $bb-red-light !important;
      background-repeat: no-repeat;
      background-position: right;
      background-color: $bb-red-light !important;
      background-size: 26px 26px !important;
      background-image: url('../../assets/svg/engine-warning.svg') !important;
    }

    div[class$='-control'] {
      background-color: $bb-red-light !important;
    }
    .input-group {
      flex-wrap: initial !important;
      &.no-border-left {
        .input-group-append {
          p {
            background-color: $bb-red-light !important;
          }
        }
      }
    }
  }

  > .label-container {
    display: none;
    &.show {
      display: flex;
      margin-bottom: -10px !important;
    }
  }

  .input-group {
    flex-wrap: initial !important;
    &.no-border-left {
      .input-group-append {
        p {
          border: none !important;
          color: $bb-grey-4;
          border-radius: 4px 0px 0px 4px !important;
          font-weight: bolder;

          &.disabled {
            background-color: $bb-grey-9;
            opacity: 0.7;
            border: 1px solid $bb-grey-5 !important;
            border-right: none !important;
          }
        }
      }

      .css-b62m3t-container {
        .css-1s2u09g-control,
        .css-1pahdxg-control {
          border-radius: 0px 4px 4px 0px !important;
        }
      }
      .css-3iigni-container {
        width: 100%;
        .css-1insrsq-control {
          background-color: $bb-grey-9;
          opacity: 0.7;
          border: 1px solid $bb-grey-5;
          box-sizing: border-box;
          border-radius: 0px 4px 4px 0px !important;
          border-left: none !important;
          color: $bb-grey-4;
        }
      }
    }
    .css-b62m3t-container {
      width: 100%;

      // Input selector
      .css-1s2u09g-control {
        background-color: $bb-grey-9;
        border: 0;
        border-radius: 4px;
      }

      // Input selector
      .css-1pahdxg-control,
      .css-1pahdxg-control:hover,
      .css-1pahdxg-control:active {
        border-color: transparent;
        border: 0;
        box-shadow: 0 0 0 0 $bb-grey-7;
        background-color: $bb-grey-7;
        &:hover,
        &:active {
          border-color: transparent;
          border: 0;
          box-shadow: 0 0 0 0 $bb-grey-7;
          background-color: $bb-grey-7;
        }
      }

      // Options selected multiple (span)
      div[class$='-control'] {
        .css-1rhbuit-multiValue {
          background: $bb-secondary;
          color: $bb-white;
          border-radius: 4px;
          > div {
            &:hover {
              background: $bb-secondary;
              color: $bb-white;
            }
            color: $bb-white;
          }
        }
      }

      .css-26l3qy-menu {
        background: $bb-white;
        box-shadow: 0px 3px 10px rgba(216, 216, 216, 0.6);
        border-radius: 4px;
        > div {
          // Option selected
          .css-9gakcf-option {
            background: $bb-secondary;
            color: $bb-white;
          }

          // Option hover
          .css-1n7v3ny-option {
            background: $bb-secondary-light;
            color: $bb-secondary;
          }

          // Option default
          // .css-yt9ioa-option {
          //   background: green;
          //   color: $bb-white;
          // }
        }
      }
    }

    // Input disabled
    .css-3iigni-container {
      width: 100%;
      .css-1insrsq-control {
        background-color: $bb-grey-9;
        opacity: 0.7;
        border: 1px solid $bb-grey-5;
        box-sizing: border-box;
        border-radius: 4px;
        color: $bb-grey-4;

        .css-1rhbuit-multiValue {
          background-color: $bb-secondary;
          border-radius: 4px;
          div {
            color: $bb-white;
          }
        }
      }
    }
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
  }

  // Arrow SVG
  .css-1s2u09g-control,
  .css-1pahdxg-control,
  .css-1insrsq-control {
    div {
      div.css-tlfecz-indicatorContainer:last-child,
      div.css-1gtu0rj-indicatorContainer:last-child {
        > svg {
          padding-top: 1px;
          color: $bb-grey-2;

          > path {
            d: path(
              'M14.1904 5H9.00196V12.0854C9.20126 12.0846 9.39284 12.0082 9.53805 11.8717L9.57179 11.8379L14.7602 6.43207C14.8742 6.31537 14.9518 6.16814 14.9838 6.00821C15.0158 5.84828 15.0007 5.68251 14.9404 5.53097C14.8801 5.37943 14.7772 5.24863 14.6441 5.15439C14.5109 5.06015 14.3534 5.00652 14.1904 5ZM8.46199 11.8757C8.60814 12.0131 8.80123 12.0895 9.00183 12.0894V5.00024H3.81339C3.65011 5.00608 3.49203 5.0592 3.35837 5.15317C3.22471 5.24714 3.12122 5.37791 3.06047 5.52958C2.99972 5.68125 2.98433 5.84732 3.01617 6.00757C3.04801 6.16783 3.1257 6.31538 3.23981 6.43232L8.432 11.8419L8.46199 11.8757Z'
            ) !important;
          }
        }
      }
    }
  }
}
