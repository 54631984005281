.container {
  padding: 15px !important;
  @include media-breakpoint-up(lg) {
    padding: 25px !important;
  }
}
.content {
  padding: 0 !important;
  margin: 0 !important;
}
