@import '../../../../../assets/scss/index.scss';

.bb-wholesaler-pack-form {
  .bb-form-group {
    margin: 0;
  }

  input {
    max-width: 350px;
  }

  &__label {
    margin-top: 10px;
    color: $bb-grey-3;
  }

  &__renovation-button {
    display: flex;
    align-items: center;
  }

  &__cancel-button {
    display: flex;
    align-items: center;

    button {
      background-color: $bb-white !important;
      color: $bb-grey-3 !important;
      box-shadow: 0px 3px 10px rgba(216, 216, 216, 0.4);
      margin-right: 5px;
    }
  }

  .bb-wholesaler-pack-tag {
    margin-bottom: 20px;
  }

  &__p-date {
    margin-bottom: 7px !important;
    color: $bb-grey-2 !important;
  }

  &__p-paypal {
    color: $bb-grey-2 !important;
  }

  .bb-separator--dash {
    margin: 25px 0;
  }

  @include media-breakpoint-up(md) {
    &__p-date {
      padding-top: 5px;
    }

    .bb-wholesaler-pack-tag {
      margin-bottom: 25px;
    }

    .bb-separator--dash {
      margin: 30px 0;
    }
  }

  @include media-breakpoint-down(md) {
    &__p-paypal {
      margin-bottom: 20px !important;
    }
  }
}
