.bb-form-spinner {
  height: 100%;

  & .bb-formcard {
    height: 100%;

    &__container {
      height: auto;
    }
  }
}
