@import '../../../../assets/scss/index.scss';

.bb-my-accout-layout {
  .bb-profile-card,
  .bb-pack-info-card.bb-pack-info-card--normal,
  .bb-wallet-card {
    height: 100% !important;
  }

  > div {
    margin-bottom: 15px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 25px;
    }
  }
}
