@import 'app/assets/scss/index.scss';

.bb-user-additional-panel {
  display: block;

  & > hr {
    margin: 25px 0px;
    @include media-breakpoint-up(lg) {
      margin: 30px 0px;
    }
  }

  &__header {
    margin-bottom: 20px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 25px !important;
    }

    &__row {
      & > p {
        color: $bb-grey-2;
      }
    }
  }

  &__company {
    &__column {
      & > p {
        color: $bb-grey-2;
        margin-bottom: 20px !important;
        @include media-breakpoint-up(lg) {
          margin-bottom: 25px !important;
        }
      }

      & > div {
        margin-bottom: 0px;
        height: 70px;
      }
    }
  }

  &__sell-channel {
    &__title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 0px !important;
      }
      & > p {
        color: $bb-grey-2;
      }
    }

    &__checkboxes {
      margin-bottom: 20px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 25px !important;
      }
      & > div {
        margin-bottom: 10px !important;

        &:last-child {
          margin-bottom: 0px !important;
        }
      }
    }
  }

  &__online-shop {
    align-items: center;
    &__title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 0px !important;
      }
      & > p {
        color: $bb-grey-2;
      }
    }

    &__radiobuttons {
      display: flex;
      & > div {
        margin-right: 25px !important;

        &:last-child {
          margin-right: 0px !important;
        }
      }
    }
  }

  &__input-web {
    margin-top: 20px;
    @include media-breakpoint-up(lg) {
      margin-top: 25px;
    }
    &__input {
      margin-bottom: 0px !important;
      @include media-breakpoint-up(lg) {
        margin-bottom: -18px !important;
      }
    }
  }

  &__contact {
    align-items: center;
    &__title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;
      & > p {
        color: $bb-grey-2;
      }
    }
    &__radiobuttons {
      display: flex;
      & > div {
        margin-right: 25px !important;

        &:last-child {
          margin-right: 0px !important;
        }
      }
    }
  }

  &__select {
    margin-top: 20px;

    .bb-select.error {
      .fv-plugins-message-container {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
          'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
          'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
          'Noto Color Emoji';
        -ms-text-size-adjust: 100%;
      }
    }
  }
}
