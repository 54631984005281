@import 'app/assets/scss/index.scss';

.bb-data-table {
  position: relative;
  &.loading {
    min-height: 304px;
  }

  &.loading {
    min-height: 304px;
  }

  &.one-entitie {
    .bb-dropdown {
      overflow-y: scroll;
      height: 100px;
    }
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  &__searchbar {
    @include media-breakpoint-up(md) {
      padding-right: 7px !important;
    }
  }

  &__filters {
    margin: 25px 0 25px 0;
    &-buttons {
      display: flex;
      margin-top: 20px;
      margin-bottom: -18px;
      gap: 14px;
      @include media-breakpoint-up(md) {
        margin-top: 0;
        padding-left: 0 !important;
        gap: 7px;
      }
    }
  }

  &__tag {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 7px 15px;
    text-align: center;
    border-radius: 4px;
    max-width: 117px;

    & label {
      font-size: 12px !important;
      font-weight: 500 !important;
      margin-bottom: 0;
    }

    &-tooltip {
      margin: 4px;
      display: inline;
    }
  }

  &-card-notifications {
    margin-top: 25px !important;
    &__active {
      &-msg {
        margin-top: 25px;
      }
    }
    &__active {
      &-form {
        margin-top: 25px;
        &-label {
          margin-bottom: 5px;
        }
        div.col-4 {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }

  .pagination__container {
    @include media-breakpoint-up(md) {
      margin-top: 5px;
    }
    .pagination-links {
      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }
    .pagination-toolbar__text {
      color: $bb-grey-2;
    }
  }

  .react-bootstrap-table {
    .table.table-head-custom {
      .form-check {
        display: flex !important;
        padding: 10px;
      }
      th,
      td {
        &:first-child {
          padding-left: 0 !important;
        }
        &:last-child {
          padding-right: 0 !important;
        }
      }
      thead {
        white-space: nowrap !important;
        th {
          font-family: 'Roboto' !important;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: $bb-grey-4;
          letter-spacing: 0 !important;
          padding: 0 5px !important;
          min-height: 40px !important;
          height: 40px !important;
        }
      }
      tbody {
        height: 82px;
        tr {
          color: $bb-grey-2;
          td {
            font-family: 'Roboto' !important;
            font-style: normal;
            font-size: 12px !important;
            line-height: 14px !important;
            padding: 10px 5px !important;
            &:last-child {
              width: 0;
            }
            &.rma-editable-row > p,
            &.rma-editable-row > .bb-textarea-form-group > textarea {
              font-size: 12px !important;
              line-height: 14px !important;
            }
            
            &.rma-editable-row {
              .bb-select {
                .input-group {
                  .css-b62m3t-container {
                    .css-1s2u09g-control {
                      .css-319lph-ValueContainer {
                        .css-qc6sy-singleValue {
                          width: 50px !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          &.red {
            color: $bb-red !important;
            .date-formatter {
              &__date {
                color: $bb-red;
              }
              &__hour {
                color: $bb-red-medium;
              }
            }
          }

          &.bold {
            .date-formatter {
              &__date {
                font-weight: bold !important;
              }
              &__hour {
                font-weight: bold !important;
              }
            }
          }

          .date-formatter {
            &__date {
              color: $bb-grey-2;
            }
            &__hour {
              color: $bb-grey-3;
            }
          }

          .actions-formatter {
            display: flex;
            button {
              height: 32px;
              width: 32px;
              margin-right: 7px;
              cursor: pointer;
            }
          }
        }
        .bb-badge {
          white-space: nowrap !important;
        }
      }
    }
  }

  .bb-spinner-container {
    position: absolute;
    background-color: rgba($bb-grey-9, 0.6);
  }

  .pagination-no-action {
    cursor: default;
    color: $bb-grey-4 !important;
    &:hover {
      background-color: inherit;
      color: inherit;
    }
    i {
      color: $bb-grey-4 !important;
    }
  }
  .pagination-links__btn {
    margin: 0 2px;
  }
}

.export-csv-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  flex-wrap: wrap;
  @include media-breakpoint-up(md) {
    gap: 7px;
  }

  .pagination-links__btn {
    margin: 0 2px;
  }
}
