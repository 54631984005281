//
// SVG Icon
//
svg.bb-icon {
  &.small {
    width: $bb-icon-small !important;
    height: $bb-icon-small !important;
  }
  &.medium {
    width: $bb-icon-medium !important;
    height: $bb-icon-medium !important;
  }
  &.large {
    width: $bb-icon-large !important;
    height: $bb-icon-large !important;
  }

  [fill] {
    transition: fill 0.3s ease;
    fill: default !important;
  }

  &:hover {
    [fill] {
      transition: fill 0.3s ease;
      fill: default !important;
    }
  }

  &.hover-blue {
    &:hover {
      [fill] {
        transition: fill 0.3s ease;
        fill: $bb-secondary !important;
      }
    }
  }
}

//  SVG icons in menu aside
li.menu-item {
  &.menu-item-active {
    svg.bb-icon {
      [fill] {
        fill: $bb-primary !important;
      }
    }
  }

  &:hover {
    svg.bb-icon {
      [fill] {
        fill: $bb-primary !important;
      }
    }
  }
}

//  SVG icons in menu
.header-mobile {
  button {
    &.active,
    &.mobile-toggle-active {
      > svg,
      > svg:hover,
      > svg:active {
        [fill] {
          transition: fill 0.3s ease;
          fill: $bb-primary !important;
        }
      }
    }
  }
}

// SVG icons top bar
.topbar {
  svg.bb-icon {
    [fill] {
      transition: fill 0.3s ease;
      fill: $bb-secondary !important;
    }
  }
}
