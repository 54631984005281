@import 'app/assets/scss/index.scss';

.user-notifications {
  border: 0 !important;

  // Generic styles
  &-body {
    width: 100%;
    &__item {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 8px 24px;
      background-color: #f5efdc;

      &.read {
        background-color: $bb-white;
      }

      &:hover {
        background-color: #f7df93;

        .notification-icon {
          svg {
            g [fill] {
              fill: #50565b;
            }
          }
        }
        .user-notifications-body__item-button {
          svg {
            color: #50565b;
          }
        }
      }
      &:active {
        background-color: #f8d462;
      }

      &-content {
        display: flex;
        flex-direction: row;

        .notification-icon {
          margin-right: 8px;
          max-width: 26px;
          min-width: 26px;
          width: 26px;
        }

        .notification-text {
          &__title {
            color: #50565b;
          }
          &__description {
            color: #50565b;
          }
        }
      }

      &-button {
        background-color: transparent;
      }
    }
    // Styles no notifications
    &__empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 25px;

      p {
        margin-top: 25px;
        color: $bb-grey-3;
      }
    }
  }

  // Styles notifications on dropdown
  &.dropdown {
    width: 343px;
    background-color: $bb-white;
    .user-notifications-body {
      &__item {
        &-button {
          background-color: transparent !important;
        }
      }
    }
  }
}
