.bb-select-html {
  background-color: $bb-grey-9 !important;
  background-image: url(../../svg/caret-down.svg);
  background-repeat: no-repeat;
  background-position: 96% center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: none !important;
  border-radius: 4px !important;
  height: 38px !important;

  &:active,
  &:focus {
    background-color: $bb-grey-7 !important;
  }

  &:disabled {
    background-color: $bb-grey-9 !important;
    opacity: 0.7 !important;
    border: 1px solid $bb-grey-5 !important;
  }
}
