@import 'app/assets/scss/index.scss';

.card-notification-alert {
  padding: 16px;
  margin-bottom: 16px;
  @include media-breakpoint-up(md) {
    padding: 24px;
    margin-bottom: 24px;
  }

  .bb-card__container {
    width: 100%;
    h5 {
      margin-bottom: 0;
    }

    .user-notification-alert {
      border-radius: 4px;
      width: 100%;
      margin-top: 25px;
      padding: 16px;
      @include media-breakpoint-up(md) {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
      }

      &.alert--error {
        background-color: #fbecee;
        svg.alert-icon {
          [fill] {
            fill: #cf0e08 !important;
          }
        }
      }

      &.alert--warning {
        background-color: #f5efdc;
        svg.alert-icon {
          [fill] {
            fill: #f2b801 !important;
          }
        }
      }

      &.alert--info {
        background-color: #dceaf6;
        svg.alert-icon {
          [fill] {
            fill: #0090ff !important;
          }
        }
      }

      &__container {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }

        &-header {
          margin-right: 16px;
          svg {
            width: 18px;
            height: 18px;
          }
        }

        &-body {
          .title-alert {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px !important;

            svg {
              cursor: pointer;
              @include media-breakpoint-up(md) {
                display: none;
              }
            }
          }
        }
      }

      &__footer {
        display: flex;
        align-items: center;
        gap: 16px;
        @include media-breakpoint-up(md) {
          svg.notification-dissmiss {
            cursor: pointer;
            display: block !important;
          }
        }

        button {
          white-space: nowrap;
          width: 100%;
        }

        svg.notification-dissmiss {
          display: none;
        }
      }
    }
  }
}