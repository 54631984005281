@import 'app/assets/scss/index.scss';

.bb-user-panel {
  display: block;
  user-select: none;

  &__header {
    margin-bottom: 20px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 25px;
    }
    &__title {
      color: $bb-grey-2;
    }
  }

  &__personal {
    margin-bottom: 20px;
    align-items: flex-start;
    @include media-breakpoint-up(lg) {
      margin-bottom: 25px;
    }

    & .user-form-label {
      @include media-breakpoint-up(lg) {
        min-height: 38px;
        display: flex;
        align-items: center;
      }
    }

    &__title {
      margin-bottom: 10px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 0px;
      }
    }

    &__container {
      display: flex;
      flex-direction: column;

      &__row {
        display: flex;
        position: relative;
        flex-direction: column;
        @include media-breakpoint-up(lg) {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }

        &__logo {
          width: 75px;
          height: 75px;
          background: $bb-grey-9;
          border-radius: 4px;
        }

        & > span {
          position: absolute;
          top: 0px;
          left: 70px;
          width: 18px;
          height: 18px;
          background: $bb-white;
          box-shadow: 0px 3px 10px rgba(181, 181, 195, 0.3);
          border-radius: 50%;
          cursor: pointer;

          & > svg {
            position: relative;
            top: 6px;
            left: 8px;
          }
        }

        & > img {
          width: 75px;
          height: 75px;
          border-radius: 4px;
        }

        &__title {
          margin-top: 10px;
          @include media-breakpoint-up(lg) {
            width: 70%;
            padding: 10px;
            margin-top: 0;
            margin-left: 0;
          }
          & > p {
            color: $bb-grey-4;
          }
        }
      }
    }

    &__description {
      & > p {
        color: $bb-grey-3;
      }
    }

    &__input {
      & > div {
        margin-bottom: 0px;
      }
      &-label {
        min-height: 38px;
      }
    }

    &:nth-last-child(2) {
      margin-bottom: 0px !important;
    }
  }

  &__description {
    margin-top: 10px;
    &__title {
      color: $bb-grey-3;
    }
  }

  & > hr {
    width: 100%;
    margin: 25px 0px;
    @include media-breakpoint-up(lg) {
      margin: 30px 0px;
    }
  }

  &__verification {
    &-title {
      &-container {
        display: flex;
        padding: 20px 0 7px 0;
        > div.form-check {
          margin-right: 25px !important;
        }
        @include media-breakpoint-up(lg) {
          padding: 25px 0 10px 0;

          > div.form-check {
            margin-right: 30px !important;
          }
        }
      }
    }
  }
}
