@import 'app/assets/scss/utils/variables.scss';

.bb-badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: max-content;
  border-radius: 4px;
  cursor: default;

  &__orange {
    > div {
      color: $bb-orange;
    }
    background-color: $bb-orange-light;
  }
  &__red {
    > div {
      color: $bb-red;
    }
    background-color: $bb-red-light;
  }
  &__blue {
    > div {
      color: $bb-secondary;
    }
    background-color: $bb-secondary-light;
  }
  &__yellow {
    > div {
      color: $bb-primary;
    }
    background-color: $bb-primary-light;
  }
  &__green {
    > div {
      color: $bb-green;
    }
    background-color: $bb-green-light;
  }
  &__purple {
    > div {
      color: $bb-purple;
    }
    background-color: $bb-purple-light;
  }
  &__grey {
    > div {
      color: $bb-grey-3;
    }
    background-color: $bb-grey-7;
  }

  &__medium {
    padding: 9px 15px;
  }
  &__small {
    padding: 7px 15px;
  }
  &__xsmall {
    padding: 4px 7px;
  }

  > div {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  }
  > svg {
    width: 16px;
    height: 14px;
  }
}
