@import 'app/assets/scss/utils/variables.scss';

.select-data-range {
  width: 100%;

  #select-picker {
    #react-select-2-listbox {
      z-index: 1;
    }
    .css-1s2u09g-control,
    .css-1pahdxg-control,
    .css-1insrsq-control,
    .css-1pahdxg-control:hover,
    .css-1pahdxg-control:active {
      background-color: $bb-grey-9 !important;
      border: none !important;
      border-radius: 4px !important;
      box-shadow: 0 0 0 0 $bb-grey-9;

      .css-1okebmr-indicatorSeparator {
        display: none;
      }

      div.css-tlfecz-indicatorContainer,
      div.css-1gtu0rj-indicatorContainer {
        > svg {
          color: $bb-grey-3;
          padding-top: 1px;

          > path {
            d: path(
              'M14.1904 5H9.00196V12.0854C9.20126 12.0846 9.39284 12.0082 9.53805 11.8717L9.57179 11.8379L14.7602 6.43207C14.8742 6.31537 14.9518 6.16814 14.9838 6.00821C15.0158 5.84828 15.0007 5.68251 14.9404 5.53097C14.8801 5.37943 14.7772 5.24863 14.6441 5.15439C14.5109 5.06015 14.3534 5.00652 14.1904 5ZM8.46199 11.8757C8.60814 12.0131 8.80123 12.0895 9.00183 12.0894V5.00024H3.81339C3.65011 5.00608 3.49203 5.0592 3.35837 5.15317C3.22471 5.24714 3.12122 5.37791 3.06047 5.52958C2.99972 5.68125 2.98433 5.84732 3.01617 6.00757C3.04801 6.16783 3.1257 6.31538 3.23981 6.43232L8.432 11.8419L8.46199 11.8757Z'
            ) !important;
          }
        }
      }
    }
    .css-26l3qy-menu {
      background: $bb-white;
      box-shadow: 0px 3px 10px rgba(216, 216, 216, 0.6);
      border-radius: 4px;
      > div {
        // Option selected
        .css-9gakcf-option {
          background: $bb-secondary;
          color: $bb-white;
        }

        // Option hover
        .css-1n7v3ny-option {
          background: $bb-secondary-light;
          color: $bb-secondary;
        }
      }
    }
  }

  .react-datepicker {
    display: flex;
    justify-content: center;
    width: 100%;
    box-shadow: 0px 3px 10px rgba(216, 216, 216, 0.6);
    border-radius: 4px;
    border: 0;
    margin-top: 10px;

    button {
      align-items: center;
      background: none;
      display: flex;
      justify-content: center;
      text-align: center;
      cursor: pointer;
      position: initial;
      top: 2px;
      padding: 0;
      border: none;
      z-index: 1;
      height: 32px;
      width: 32px;
      text-indent: -999em;
      overflow: hidden;

      .react-datepicker__navigation-icon::before {
        border-color: $bb-grey-3;
      }
    }

    button.react-datepicker__navigation--previous {
      order: 1;
    }
    .react-datepicker__month-container {
      order: 2;
    }
    button.react-datepicker__navigation--next {
      order: 4;
    }

    .react-datepicker__month-container {
      order: 2;

      .react-datepicker__header {
        background-color: $bb-white;
        border: 0;
        .react-datepicker__header__dropdown {
          select {
            border: 0;
            background-color: transparent;
          }
        }
      }

      .react-datepicker__current-month {
        display: none;
      }

      .react-datepicker__month {
        .react-datepicker__week {
          .react-datepicker__day--keyboard-selected {
            background-color: $bb-secondary;
            color: $bb-white;
            border-radius: 25px;
          }
          .react-datepicker__day--in-range,
          .react-datepicker__day--in-selecting-range {
            background-color: $bb-secondary-light;
            color: $bb-secondary;
          }

          .react-datepicker__day--range-start,
          .react-datepicker__day--selecting-range-start {
            background: $bb-secondary;
            border-radius: 11px 0 0 11px;
            color: $bb-white;
          }

          .react-datepicker__day--range-end {
            background: $bb-secondary;
            border-radius: 0 11px 11px 0;
            color: $bb-white;
          }
        }
      }
    }
  }
}
