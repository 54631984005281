.bb-timeline {
  display: flex;
  flex-direction: column;

  & .bb-time {
    min-height: 50px;

    &__left {
      flex-shrink: 0;
    }

    &__left,
    &__right {
      justify-content: flex-start;
    }

    &__spaced {
      &__circle {
        margin: 0 15px;
      }
      &__line {
        height: 100%;
      }
    }
  }
}
